import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import theme from "../../app/theme";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {CRUDDialogProps} from "./crudDialog.interface";
import DialogAlert from "../dialogAlert/dialogAlert";
import {AnyObject} from "react-final-form";
import {FormApi, SubmissionErrors} from "final-form";
import Form from "../form/Form";
import {signal} from "@preact/signals-react";
import {FormState} from "../form/form.interface";
import FormStateControl from "../form/FormStateControl";
import FormSubmitButton from "../form/FormSubmitButton";

const CrudDialog = ({
    title,
    open,
    setOpen,
    content,
    initialValues,
    onDelete,
    submitButtonLabel,
    onSubmit,
    formMeta
}: CRUDDialogProps) => {
    const {t} = useTranslation();
    const formsState = signal<FormState[]>([])
    const formRef = useRef<HTMLFormElement>(null);
    const [dialogState, setDialogState] = useState<{
        deleting: boolean,
        errors?: string[]
    }>({
        deleting: false,
    })
    let dialogForm: FormApi;

    const handleClose = () => {
        dialogForm && dialogForm.reset()
        setOpen(false);
    };

    const handleSubmit = async (values: AnyObject, form: FormApi): Promise<SubmissionErrors | void> => {
        const errors = await onSubmit(values, form)

        if (!errors || Object.keys(errors).length === 0) {
            dialogForm && dialogForm.reset()
            setOpen(false);
        }

        return errors;
    }

    const handleDelete = async () => {
        if (!onDelete) {
            return;
        }
        setDialogState(prevState => ({...prevState, deleting: true}))
        const errors = await onDelete();

        if (errors) {
            setDialogState(prevState => ({...prevState, errors: errors}))
        } else {
            handleClose();
        }
        setDialogState(prevState => ({...prevState, deleting: false}))
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle sx={{
                pl: 6,
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.secondary.light
            }}>{t(title)}</DialogTitle>
            <DialogContent sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    maxWidth: 700,
                    m: 1,
                    flexDirection: 'column',
                    '& .MuiFormControl-root': {
                        minHeight: 100
                    }
                }}>
                <Form
                    onSubmit={handleSubmit}
                    formMeta={formMeta}
                    initialValues={initialValues}
                    subscription={{
                        submitError: true,
                    }}
                    render={({
                         form,
                         handleSubmit,
                         submitError,
                     }) => {
                        const dialogErrors: string[] = dialogState.errors?.length ? dialogState.errors : submitError
                        if (form.getState().values === undefined) {
                            form.reset()
                        }

                        dialogForm = form

                        return (
                            <React.Fragment>
                                <Box sx={{mb: 2}}>
                                    {dialogErrors?.map((message, index) => (
                                        <DialogAlert key={index} message={message}/>
                                    ))}
                                </Box>
                                <form style={{width: '100%'}} ref={formRef} onSubmit={handleSubmit}>
                                    <FormStateControl formsState={formsState} formRef={formRef} name={'CrudDialog'} />
                                    {content()}
                                </form>
                            </React.Fragment>
                        )
                    }}
                />
                </Box>
            </DialogContent>
            <DialogActions sx={{
                mb: 3,
                mx: 3,
                display: 'flex',
            }}>
                <Box sx={{width: '100%'}}>
                    {onDelete && <LoadingButton
                        size={"large"}
                        variant={"contained"}
                        color={"error"}
                        loading={dialogState.deleting}
                        onClick={handleDelete}>
                        {t('common.delete')}
                    </LoadingButton>}
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <FormSubmitButton title={t(submitButtonLabel)} formsState={formsState}/>
                    <Button color={'inherit'} size={"large"} sx={{mr: 2}} onClick={handleClose}>{t('common.cancel')}</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(CrudDialog)