import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

const initialState = {} as {[key: string]: {[key: string]: string}}

const slice = createSlice({
    name: 'breadcrumbs',
    initialState,
    reducers: {
        enableBreadcrumbsForPath: (state, {payload}: PayloadAction<{pathname: string, data: {[key: string]: string}}>) => {
            state[payload.pathname] = payload.data
        },
    }
})

export const { enableBreadcrumbsForPath } = slice.actions
export default slice.reducer

export const selectBreadcrumbsDataForPath = (state: RootState, pathname: string) => state.breadcrumbs[pathname]