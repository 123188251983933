import React from "react";
import {Checkboxes, TextField} from "mui-rff";
import {FieldArray, FieldArrayRenderProps} from "react-final-form-arrays";
import {useTranslation} from "react-i18next";
import {Field} from "react-final-form";
import {TaskAgendaItem} from "./task.interface";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import theme from "../../app/theme";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {GridAddIcon} from "@mui/x-data-grid";
import Button from "@mui/material/Button";

const AgendaItemContainer = styled(Box)(({ theme }) => ({
    marginTop: 10,
    paddingLeft: 25,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12), inset 10px 0px 1px 0px ' + theme.palette.primary.dark,

    '&:hover': {
        boxShadow: '0px 2px 2px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12), inset 10px 0px 1px 0px ' + theme.palette.primary.dark,

        '& .Actions': {
            display: 'block'
        },
    },
    '&:has(input.RowIsDeleted[value="true"])': {
        display: 'none'
    }
}));

const TaskAgendaField = ({
    updateRowField,
    addNewRow
 }: {
    updateRowField: (index: number, name: string, value: any) => void
    addNewRow: (rowData: any) => void
}) => {
    const {t} = useTranslation();

    const handleAddNewRow = () => {
        addNewRow({})
    }

    const handleDeleteRow = (index: number) => {
        updateRowField(index, 'isDeleted', true)
    }

    const renderArrayFields = ({ fields }: FieldArrayRenderProps<TaskAgendaItem, any>) => {

        return fields.map((name: string, index: number) => (
            <AgendaItemContainer key={name}>
                <Grid container columnSpacing={4}>
                    <Grid item container justifyContent={"flex-start"} alignContent={"center"} xs={10}>
                        <TextField
                            name={`${name}.description`}
                            variant={"filled"}
                            className={'MuiInput-previewed'}
                            placeholder={t('common.fieldPlaceholder') || ''}
                            fullWidth
                            multiline
                            hiddenLabel
                        />
                    </Grid>
                    <Grid item container justifyContent={"flex-end"} alignContent={"center"} xs={1}>
                        <Checkboxes
                            name={`${name}.isFinished`}
                            data={{ label: '', value: false}}
                            sx={{
                                color: theme.palette.primary.main,
                                '& .MuiSvgIcon-root': {
                                    color: theme.palette.primary.main,
                                    fontSize: '2.3rem',
                                }
                            }}
                        />
                        <Field name={`${name}.number`}>
                            {({input}) => (
                                <input {...input} hidden name={`${name}.number`}/>
                            )}
                        </Field>
                        <Field name={`${name}.isDeleted`}>
                            {({input}) => (
                                <input {...input} hidden className={'RowIsDeleted'} name={`${name}.isDeleted`}/>
                            )}
                        </Field>
                    </Grid>
                    <Grid item container justifyContent={"flex-end"} alignContent={"center"} xs={1}>
                        <Box sx={{
                            display: 'none',
                            borderLeft: '1px solid',
                            pl: 10,
                        }}>
                            <IconButton edge="end" aria-label="edit" sx={{mr: '2px'}} onClick={() => handleDeleteRow(index)}>
                                <DeleteIcon color={"error"}/>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </AgendaItemContainer>
        ))
    }

    return (
        <Box sx={{mb: 20, pt: 0, pb: 0}}>
            <FieldArray name="agendaItems" subscription={{}} isEqual={() => true}>
                {renderArrayFields}
            </FieldArray>
            <Box sx={{ display: 'flex' }} justifyContent={"center"} alignContent={"center"}>
                <Button className={'AddButton'} variant="contained" color={"primary"}
                        onClick={handleAddNewRow} startIcon={<GridAddIcon />}
                        sx={{mt: 5}}
                >
                    {t('taskAgenda.addNew')}
                </Button>
            </Box>
        </Box>
    )
}

export default TaskAgendaField;