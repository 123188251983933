import theme from "../../app/theme";

const styles = (theme: any) => {
    return {
        '--unstable_DataGrid-radius': 0,
        borderLeft: 'none',
        borderRight: 'none',

        '& .MuiDataGrid-header:not(.MuiDataGrid-headerFilterRow .MuiDataGrid-header)': {
            color: theme.palette.text.secondary
        },
        '& .MuiAutocomplete-root .MuiFormLabel-root': {
            display: 'none'
        },
        '& .MuiDataGrid-cell': {
            border: 'none'
        },
        '& .MuiDataGrid-virtualScroller': {

            '&:has(.MuiDataGrid-noRowsOverlay)': {
                minHeight: 400
            }
        },
        '& .MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
        },
        '& .MuiDataGrid-iconSeparator': {
            visibility: 'visible',
        },
        '& .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-noRowsOverlay': {
            width: '100%',
            height: '100%',
            marginTop: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
           outline: 'initial'
        },
        '& .MuiDataGrid-headerFilterRow': {
            '& .MuiMultiInputDateRangeField-root .MuiInputBase-input': {
                padding: '16px 8px',

                '&::placeholder': {
                    fontSize: '0.5em',
                },
            },
            '& .MuiInputBase-input': {
                fontSize: '0.7em',
            },
            '& .MuiFormLabel-root': {
                fontSize: '1em',
            }
        }
    };
}

export const dataGridStyles = styles(theme);