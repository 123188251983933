import Box from "@mui/material/Box";
import MemoizedLogo from "../logo/Logo";
import theme from "../../app/theme";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import UserMenu from "../userMenu/UserMenu";
import {ReactElement} from "react";
import {Card, useMediaQuery} from "@mui/material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {useNavigate} from "react-router-dom";

const openedMixin = (theme: Theme): CSSObject => ({
    width: theme.sideBarWidth,
    transition: theme.transitions.create(['width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    padding: theme.spacing(2, 2),
    height: '90vh',
    maxHeight: '850px',

    [theme.breakpoints.down('sm')]: {
        height: '100vh',
    },
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create(['width', 'height'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    height: '90vh',
    maxHeight: '850px',

    [theme.breakpoints.down('sm')]: {
        width: `calc(${theme.spacing(11)} + 1px)`,
        height: theme.spacing(12),
        paddingTop: 5
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: theme.sideBarWidth,
        flexShrink: 0,
        height: '100%',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        paddingBottom: theme.spacing(5),
        '& .MuiDrawer-paper': {
            position: 'initial',
            height: '100%',
            border: 'none',
            overflowX: 'hidden',
            backgroundColor: 'transparent',
        }
    }),
);

export type MenuItem = {
    icon: ReactElement,
    label: string,
    url: string
}

export type SideBarProps = {
    menuItems: MenuItem[],
}

const SideBar = ({
    menuItems,
}: SideBarProps) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(!isMobile);
    const navigate = useNavigate();

    const handleDrawerToogle = () => {
        setOpen(!open);
    };

    const WrapperComponent: OverridableComponent<any> = isMobile ? Box : Card

    return (
        <React.Fragment>
            <Box sx={{
                display: isMobile ? 'none' : 'block',
                overflow: 'initial',
                flexShrink: 0,
                width: open ? theme.sideBarWidth + 'px' : (isMobile
                    ? `calc(${theme.spacing(7)} + 1px)`
                    : `calc(${theme.spacing(8)} + 1px)`
                ),
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            }}/>
            <WrapperComponent sx={{
                overflow: 'initial',
                position: 'fixed',
                zIndex: 1000,
                top: isMobile ? 0 : '3vh',
                maxHeight: '850px',
                backgroundColor: open || !isMobile ? theme.palette.secondary.light : 'transparent',
            }}>
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <Box sx={{
                            flexGrow: 1,
                            opacity: open ? 1 : 0,
                            display: open ? 'block' : 'none' ,
                        }}>
                            <MemoizedLogo firstColor={theme.palette.secondary.dark} secondColor={theme.palette.primary.main} fontSize={'1.4em'}/>
                        </Box>
                        <IconButton onClick={handleDrawerToogle} sx={{
                            background: theme.palette.secondary.light,
                            border: !open && isMobile ? `1px solid ${theme.palette.secondary.dark}` : 'none'
                        }}>
                            {open ? <ChevronLeftIcon /> : <MenuIcon />}
                        </IconButton>
                    </DrawerHeader>
                    { open || !isMobile ? <Divider /> : null}
                    <List sx={{
                        height: '100%',
                        pt: 4,
                        px: '5px',
                        opacity: open || !isMobile ? 1 : 0,
                        display: open || !isMobile ? 'block' : 'none' ,
                    }}>
                        {menuItems.map((menuItem, index) => (
                            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton onClick={() => navigate(menuItem.url)}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        backgroundColor: theme.palette.primary.light,
                                        color: theme.palette.primary.contrastText,
                                        borderRadius: theme.shape.borderRadius,
                                        px: 2.5,
                                }}>
                                    <ListItemIcon sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: theme.palette.primary.contrastText,
                                    }}>
                                        {menuItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.label} sx={{
                                        display: open ? 'block' : 'none' ,
                                        opacity: open ? 1 : 0
                                    }}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{
                        opacity: open || !isMobile ? 1 : 0,
                        display: open || !isMobile ? 'block' : 'none' ,
                    }}>
                        <UserMenu showUserName={open}/>
                    </Box>
                </Drawer>
            </WrapperComponent>
        </React.Fragment>
    )
}

export default React.memo(SideBar)