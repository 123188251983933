import {FormSpy} from "react-final-form";
import React, {useEffect} from "react";
import {Signal} from "@preact/signals-react";
import {FormState} from "./form.interface";

type FormStateControlProps = {
    name: string,
    formRef: React.RefObject<HTMLFormElement>,
    formsState: Signal<FormState[]>
}

const FormStateControl = ({
    name,
    formRef,
    formsState
}: FormStateControlProps) => {
    return (
        <FormSpy>
            {({submitting, pristine, hasSubmitErrors, hasValidationErrors, dirtySinceLastSubmit}) => {
                useEffect(() => {
                    const form = {
                        formRef: formRef,
                        name: name,
                        changed: !pristine && (!hasSubmitErrors || dirtySinceLastSubmit),
                        valid: !hasValidationErrors,
                        submitting: submitting
                    }

                    formsState.value = [
                        ...formsState.peek().filter(value => value.name !== form.name),
                        form
                    ]
                }, [submitting, pristine, hasSubmitErrors, hasValidationErrors, dirtySinceLastSubmit]);

                return null;
            }}
        </FormSpy>
    )
}

export default React.memo(FormStateControl)