import {AnyObject} from "react-final-form";
import {FormFieldMeta, FormMeta} from "./form.interface";
import {ValidationError, ValidationMessage} from "../../service/validator/validation.error";
import i18n from "i18next";
import {displayErrors} from "../../service/errorService";
import * as Yup from "yup";
import {ArraySchema, StringSchema} from "yup";
import {ApiError} from "../../api/api.interface";
import {FORM_ERROR, FormApi} from 'final-form'
import * as objectUtils from "lodash";

export const processSubmitErrors = (errors: any, formMeta: FormMeta, displayFormErrors?: boolean): AnyObject & {[FORM_ERROR]?: string[]} => {
    let groupedErrors: AnyObject & {[FORM_ERROR]?: string[]} = {}

    if (errors instanceof ValidationError) {
        errors.getValidationMessages().forEach((validation: ValidationMessage) => {
            const messages = validation.messages.map((value) => i18n.t(value.message))

            if (formMeta.fields[validation.fieldName] !== undefined) {
                groupedErrors[validation.fieldName] = messages
            } else {
                groupedErrors[FORM_ERROR] = [...groupedErrors[FORM_ERROR] || [], ...messages]
            }
        })

    } else {
        groupedErrors[FORM_ERROR] = (errors as ApiError)?.messages === undefined
                ? errors
                : (errors as ApiError).messages
    }

    if (displayFormErrors && groupedErrors[FORM_ERROR]?.length) {
        displayErrors(groupedErrors[FORM_ERROR])
        delete groupedErrors[FORM_ERROR]
    }

    return groupedErrors
}

export const generateValidationSchema = (formMeta: FormMeta) => {
    let constraints: {[key: string]: StringSchema | ArraySchema<any, any, "">} = {}

    Object.entries(formMeta.fields).forEach(([name, fieldMeta]: [string, FormFieldMeta]) => {
        if (fieldMeta.required) {
            if (fieldMeta.type === 'collection') {
                constraints[name] = Yup.array().of(generateValidationSchema(fieldMeta as unknown as FormMeta))
            } else {
                constraints[name] = Yup.string().required('validation.notBlank')
            }
        }
    })

    return Yup.object(constraints);
}

export const getOnlyDirtyValues = (form: FormApi): AnyObject => {
    let values = {}
    const state = form.getState()

    Object.keys(state.dirtyFields).forEach((value: string) => {
        const indexOfClosingBracket = value.indexOf("]");
        const path = indexOfClosingBracket !== -1
            ? value.slice(0, indexOfClosingBracket + 1)
            : value

        const dirtyValue = objectUtils.get(state.values, path)
        objectUtils.set(values, path, dirtyValue)
    })

    return values
}