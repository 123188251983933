import React from "react";
import {useTranslation} from "react-i18next";
import {useConfirm} from "material-ui-confirm";
import {enqueueSnackbar} from "notistack";
import {useProjectMembersMetadataQuery} from "../project/projectMembersApi";
import {useTypedSelector} from "../../app/store";
import {
    useDeleteTaskAttachmentMutation,
    useEditTaskAttachmentMutation
} from "./taskAttachmentsApi";
import {selectTaskFormMeta} from "./taskMetaSlice";
import {processSubmitErrors} from "../../components/form/formUtils";
import {FORM_ERROR} from "final-form";
import CrudDialog from "../../components/crudDialog/CrudDialog";
import { TextField } from "mui-rff";
import {TaskAttachment} from "./task.interface";

export default function TaskAttachmentDialog(props: {
    open: boolean,
    setOpen: (open: boolean) => void,
    attachment: TaskAttachment,
    projectHash: string,
    taskId: number
}) {
    const {open, setOpen} = props;
    const {isLoading, isError} = useProjectMembersMetadataQuery({projectHash: props.projectHash})
    const formMeta = useTypedSelector((state) => selectTaskFormMeta(state, 'task_attachment_edit_form'))
    const {t} = useTranslation();
    const confirm = useConfirm();
    const [editTaskAttachment] = useEditTaskAttachmentMutation()
    const [deleteTaskAttachment] = useDeleteTaskAttachmentMutation()

    if (isLoading) {
        return null;
    }

    if (!formMeta || isError) {
        throw new Error('Can\'t load task_attachment_edit_form form meta')
    }

    const onSubmit = async (values: { [key: string]: any }) => {

        let errors = {}

        try {
            await editTaskAttachment({
                projectHash: props.projectHash,
                taskId: props.taskId,
                attachmentId: props.attachment.number,
                data: values
            }).unwrap()
            enqueueSnackbar(t('taskAttachments.successfullySaved'), {variant: "success"})
        } catch (e) {
            errors = processSubmitErrors(e, formMeta)
        }

        return errors
    };

    const handleDelete = async (): Promise<string[]> => {

        try {
            await confirm({
                confirmationButtonProps: {
                    color: "warning",
                },
                confirmationText: t('common.ok'),
                cancellationText: t('common.cancel'),
                title: t('taskAttachments.confirmDeleteTitle'),
                description: t('taskAttachments.confirmDeleteDescription')
            });
        } catch (e) {
            return []
        }

        let errors: string[] = []

        try {
            await deleteTaskAttachment({
                projectHash: props.projectHash,
                taskId: props.taskId,
                attachmentId: props.attachment.number
            }).unwrap();

            setOpen(false);
            enqueueSnackbar(t('taskAttachments.successfullyDeleted'), {variant: "success"})
        } catch (e) {
            errors = processSubmitErrors(e, formMeta)[FORM_ERROR] || [t('error.unknown')]
        }

        return errors
    };

    return (
        <CrudDialog
            open={open}
            setOpen={setOpen}
            title={'taskAttachments.dialog.edit'}
            onSubmit={onSubmit}
            initialValues={{
                name: props.attachment.name,
            }}
            submitButtonLabel={t('common.save')}
            onDelete={handleDelete}
            formMeta={formMeta}
            content={() => (
                <>
                    <TextField
                        name={'name'}
                        variant={"standard"}
                        label={t('taskAttachments.name')}
                        fullWidth
                    />
                </>
            )}
        />
    );
}