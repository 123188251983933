import * as React from 'react';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import {
    DataGrid as BaseDataGrid,
    DataGridProps,
    GridBody,
    GridContextProvider,
    GridFooterPlaceholder,
    GridHeader,
    GridRoot
} from "@mui/x-data-grid";
import {useDataGridComponent} from "@mui/x-data-grid/DataGrid/useDataGridComponent";
import {DataGridVirtualScroller} from "@mui/x-data-grid/components/DataGridVirtualScroller";
import {useGridInitializeState} from "@mui/x-data-grid/hooks/utils/useGridInitializeState";
import {
    headerFilteringStateInitializer,
    useGridHeaderFiltering,
} from "@mui/x-data-grid/internals";
import {useDataGridProps} from "./DataGridProps";

const DataGridRaw = React.forwardRef(function DataGrid<R extends GridValidRowModel>(
    inProps: DataGridProps<R> & {unstable_headerFilters?: boolean},
    ref: React.Ref<HTMLDivElement>,
) {
    const props = useDataGridProps(inProps);
    const privateApiRef = useDataGridComponent(props.apiRef, props);
    useGridInitializeState(headerFilteringStateInitializer, privateApiRef, props);
    useGridHeaderFiltering(privateApiRef, props);

    return (
        <GridContextProvider privateApiRef={privateApiRef} props={props}>
            <GridRoot
                className={props.className}
                style={props.style}
                sx={props.sx}
                ref={ref}
                {...props.forwardedProps}
            >
                <GridHeader />
                <GridBody VirtualScrollerComponent={DataGridVirtualScroller} />
                <GridFooterPlaceholder />
            </GridRoot>
        </GridContextProvider>
    );
});

interface DataGridComponent {
    <R extends GridValidRowModel = any>(
        props: DataGridProps<R> & React.RefAttributes<HTMLDivElement> & {unstable_headerFilters?: boolean},
    ): React.JSX.Element;
    propTypes?: any;
}

export const DataGrid = React.memo(DataGridRaw) as DataGridComponent;

DataGridRaw.propTypes = BaseDataGrid.propTypes