import React from "react";
import {ListBlockProps} from "./listBlock.interface";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {styled} from "@mui/material/styles";
import {listBlockStyles} from "./listBlockStyles";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {GridAddIcon} from "@mui/x-data-grid";

const StyledListBlock = styled('div')(listBlockStyles);

const ListBlock = <T extends unknown>({
    title,
    items,
    getItemLine,
    editButtonOnClick,
    addButton
}: ListBlockProps<T>) => {

    function generateList() {
        return items.map((item: T, index: number) => {
            if (!item) {
                return null
            }

            return (<ListItem
                key={index}
                secondaryAction={
                    <Box>
                        {!!editButtonOnClick && <IconButton edge="end" aria-label="edit" sx={{mr: '2px'}}
															onClick={() => editButtonOnClick(index)}>
							<EditIcon color={"primary"}/>
						</IconButton>}
                    </Box>
                }
            >
                <ListItemText
                    primary={getItemLine(item)}
                />
            </ListItem>)
        })
    }

    function getAddButton() {
        if (addButton === undefined) {
            return;
        }

        return <Button className={'MuiListButton-add'} size={"small"} variant="contained" color={"primary"} onClick={addButton.onClick} startIcon={<GridAddIcon />}>
            {addButton.text}
        </Button>
    }

    return (
        <StyledListBlock>
            {title && <Typography variant={"h6"} color={"rgba(0, 0, 0, 0.6)"} align={"center"}>{title}</Typography>}
            <List>
                {items && generateList()}
            </List>
            <Box display={'flex'} mt={2} justifyContent={"center"} alignContent={"center"}>
                {getAddButton()}
            </Box>
        </StyledListBlock>
    )
}

const comparison = (prevProps: ListBlockProps<any>, nextProps: ListBlockProps<any>): boolean => {
    return JSON.stringify(prevProps.items) === JSON.stringify(nextProps.items)
}

export default React.memo(ListBlock, comparison) as typeof ListBlock