import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {selectIsAuthenticated} from "./authSlice";
import {useSelector} from "react-redux";

interface RequireAuthProps {
    children: React.JSX.Element
}

const RequireAuth = ({children}: RequireAuthProps) => {
    const location = useLocation();
    const isAuthenticated = useSelector(selectIsAuthenticated)

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
};

export default RequireAuth;
