import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import WaitForLoad from "../../components/waitForLoad/WaitForLoad";
import ProjectsListGrid from "./parts/ProjectsListGrid";
import AddNewProjectButton from "../../features/project/AddNewProjectButton";
import {useGridApiRef} from "@mui/x-data-grid";
import DataGridToolbar from "../../components/dataGrid/DataGridToolbar";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import {Trans} from "react-i18next";
import {Card} from "@mui/material";

const ProjectsListPage = () => {
    const addNewRef = React.createRef<HTMLButtonElement>()
    const dataGridApiRef = useGridApiRef()

    return (
        <Container maxWidth={"lg"} sx={{pr: {xs: 0}}}>
            <WaitForLoad components={[ProjectsListGrid.name]}>
                <Grid container direction={'column'} spacing={2} sx={{flexGrow: 1, width: '100%'}}>
                    <Grid item>
                        <Card sx={{pr: {xs: 3, md: 5}, pl: {xs: 7, md: 5}, py: 2}}>
                            <Grid container>
                                <Grid item flexGrow={1}>
                                    <Typography variant={'h5'} >
                                        <Trans i18nKey={'pages.projects'}/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                    textAlign: "end"
                                }}>
                                    <AddNewProjectButton buttonRef={addNewRef} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item sx={{flexGrow: 1, width: '100%', py: 1}}>
                        <Card>
                            <Box sx={{flex: 1}}>
                                <Box p={2}>
                                    <Grid container>
                                        <Grid item flexGrow={1}>
                                        </Grid>
                                        <Grid item xs={4} sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "end",
                                            textAlign: "end",
                                            pr: 3
                                        }}>
                                            <DataGridToolbar gridApiRef={dataGridApiRef} />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <ProjectsListGrid openProjectDialog={() => addNewRef.current?.click()} gridApiRef={dataGridApiRef} />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </WaitForLoad>
        </Container>
    );
};

export default React.memo(ProjectsListPage);
