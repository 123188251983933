import React from "react";
import {useTranslation} from "react-i18next";
import {useConfirm} from "material-ui-confirm";
import {enqueueSnackbar} from "notistack";
import {useTypedSelector} from "../../app/store";
import {
    useCreateProjectMemberMutation,
    useDeleteProjectMemberMutation,
    useEditProjectMemberMutation, useProjectMembersMetadataQuery
} from "./projectMembersApi";
import {processSubmitErrors} from "../../components/form/formUtils";
import CrudDialog from "../../components/crudDialog/CrudDialog";
import PhoneNumber from "../../components/fields/phoneNumber/PhoneNumber";
import {selectProjectMembersFormMeta} from "./projectMembersMetaSlice";
import {TextField} from "mui-rff";
import {FORM_ERROR} from "final-form";
import {ProjectMember} from "./project.interface";

function CrudProjectMemberDialog(props: {
    open: boolean,
    setOpen: (open: boolean) => void,
    mode: string,
    projectMember?: ProjectMember,
    projectHash: string,
}) {
    const isCreate = props.mode === 'create'
    const {open, setOpen} = props;
    const {isLoading, isError} = useProjectMembersMetadataQuery({projectHash: props.projectHash})
    const formMeta = useTypedSelector((state) => selectProjectMembersFormMeta(state, isCreate ? 'project_member' : 'project_member_edit'))
    const {t} = useTranslation();
    const confirm = useConfirm();
    const [createProjectMember] = useCreateProjectMemberMutation()
    const [editProjectMember] = useEditProjectMemberMutation()
    const [deleteProjectMember] = useDeleteProjectMemberMutation()

    if (isLoading) {
        return null;
    }

    if (!formMeta || isError) {
        throw new Error('Can\'t load project_member_form form meta')
    }

    if (!isCreate && open && !props.projectMember) {
        enqueueSnackbar(t('common.notAllowed'), {variant: "error"})
        return null;
    }

    const onSubmit = async (values: { [key: string]: any }) => {

        const queryData = {
            projectHash: props.projectHash,
            data: values
        }
        let errors = {}

        try {
            if (!isCreate && props.projectMember) {
                await editProjectMember({...queryData, memberId: props.projectMember.number}).unwrap()
            } else {
                await createProjectMember(queryData).unwrap()
            }

            enqueueSnackbar(t('projectMember.successfullySaved'), {variant: "success"})
        } catch (e) {
            errors = processSubmitErrors(e, formMeta)
        }

        return errors
    };

    const handleDelete = async (): Promise<string[]> => {
        if (!props.projectMember) {
            return [];
        }

        try {
            await confirm({
                confirmationButtonProps: {
                    color: "warning",
                },
                confirmationText: t('common.ok'),
                cancellationText: t('common.cancel'),
                title: t('projectMember.confirmDeleteTitle'),
                description: t('projectMember.confirmDeleteDescription')
            });
        } catch (e) {
            return []
        }

        let errors: string[] = []

        try {
            await deleteProjectMember({projectHash: props.projectHash, memberId: props.projectMember.number}).unwrap();

            setOpen(false);
            enqueueSnackbar(t('projectMember.successfullyDeleted'), {variant: "success"})
        } catch (e) {
            errors = processSubmitErrors(e, formMeta)[FORM_ERROR] || [t('error.unknown')]
        }

        return errors
    };

    const getTitle = (): string => {
        return isCreate
            ? 'projectMember.dialog.newOrganizer'
            : 'projectMember.dialog.edit'
    }

    return (
        <CrudDialog
            open={open}
            setOpen={setOpen}
            title={getTitle()}
            onSubmit={onSubmit}
            initialValues={props.projectMember ? {
                name: props.projectMember.name,
                phone: props.projectMember.user.phone
            } : undefined}
            submitButtonLabel={isCreate ? t('common.create') : t('common.save')}
            onDelete={isCreate ? undefined : handleDelete}
            formMeta={formMeta}
            content={() => (
                <>
                    <TextField
                        name={'name'}
                        variant={"standard"}
                        label={t('projectMember.name')}
                        fullWidth
                    />
                    <PhoneNumber
                        name={'phone'}
                        fullWidth
                        disabled={!isCreate}
                        defaultCountry={'ua'}
                        value={props.projectMember?.user.phone}
                    />
                </>
            )}
        />
    );
}

export default React.memo(CrudProjectMemberDialog)