import theme from "../../app/theme";
import { Theme } from '@mui/material/styles';

const styles = (theme: Theme) => {
    return {

        '& .MuiList-root': {

            '& .MuiListItem-root': {

                '&:hover': {
                    backgroundColor: theme.palette.secondary.light,

                    '& .MuiListItemSecondaryAction-root': {
                        display: 'block'
                    }
                }
            },
            '& .MuiListItemSecondaryAction-root': {
                display: 'none'
            }
        }
    };
}

export const listBlockStyles = styles(theme)