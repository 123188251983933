import {api} from "../../api/api";
import {User} from "../user/user.interface";

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<{ token: string; user: User }, {login?: string, password?: string}>({
            query: (queryData) => ({
                url: 'login',
                method: 'POST',
                body: queryData,
            }),
            invalidatesTags: ['Metadata']
        }),
    }),
})

export const {
    useLoginMutation,
} = authApi

export const {
    endpoints: { login },
} = authApi