import {createSlice} from '@reduxjs/toolkit'
import type {RootState} from '../../app/store'
import {projectApi, useGetProjectQuery} from "./projectApi";
import {Project, ProjectMember, ProjectsMap} from "./project.interface";
import {projectMembersApi} from "./projectMembersApi";
import {useTypedSelector} from "../../app/store";

const initialState = {} as ProjectsMap

const slice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(projectApi.endpoints.createProject.matchFulfilled, (state, action) => {
                state[action.payload.hash] = action.payload
            })
            .addMatcher(projectApi.endpoints.updateProject.matchFulfilled, (state, action) => {
                state[action.payload.hash] = action.payload
            })
            .addMatcher(projectApi.endpoints.getProject.matchFulfilled, (state, action) => {
                state[action.payload.hash] = action.payload
            })
            .addMatcher(projectMembersApi.endpoints.createProjectMember.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash].projectMembers = action.payload
            })
            .addMatcher(projectMembersApi.endpoints.editProjectMember.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash].projectMembers = action.payload
            })
            .addMatcher(projectMembersApi.endpoints.deleteProjectMember.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash].projectMembers = action.payload
            })
    },
})

export default slice.reducer

type ProjectSelectorResponse = {
    project: Project | null,
    isLoading: boolean,
    isError: boolean
}

export const getProject = (projectHash: string): ProjectSelectorResponse  => {
    const data = useTypedSelector(state => state.projects?.[projectHash]);
    const response: ProjectSelectorResponse = {project: null, isLoading: false, isError: false}
    response.project = data

    // Fetch data using the RTK Query if not available in the state
    const {isLoading, isError} = useGetProjectQuery({projectHash})
    response.isLoading = isLoading
    response.isError = isError

    return response;
}
export const getProjectMembers = (state: RootState, projectHash: string): ProjectMember[] | undefined => state.projects?.[projectHash].projectMembers
