import {TabContext, TabList, TabPanel} from "@mui/lab";
import FeatureCard from "../../../components/featureCard/FeatureCard";
import theme from "../../../app/theme";
import React from "react";
import {Tab} from "@mui/material";
import {Attachment, People} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import TaskMembersListBlock from "./TaskMembersListBlock";
import TaskAttachmentsListBlock from "./TaskAttachmentsListBlock";

type RightCardProps = {
    projectHash: string,
    taskId: number,
}

const RightCard = ({
    projectHash,
    taskId
}: RightCardProps) => {
    const [selectedTab, setSelectedTab] = React.useState<string>('0');
    const {t} = useTranslation();

    return (
        <TabContext value={selectedTab}>
            <FeatureCard maxHeight sx={{
                    '& .FeatureCardHeader': {
                        py: 0
                    },
                }}
                 header={
                     <TabList
                         TabIndicatorProps={{
                             hidden: true
                         }}
                         sx={{
                             '& button.Mui-selected': {
                                 borderBottom: `2px solid ${theme.palette.primary.main}`
                             }
                         }}
                         onChange={(_: React.SyntheticEvent, newValue: string) => setSelectedTab(newValue)}
                     >
                         <Tab value={'0'} iconPosition={"start"} icon={<People/>} label={t("task.contractors")} sx={{mr: 2}} />
                         <Tab value={'1'} iconPosition={"start"} icon={<Attachment/>} label={t("task.attachments")} />
                     </TabList>
                 }
            >
                <React.Fragment>
                    <TabPanel value={'0'} sx={{p: 0}}>
                        <TaskMembersListBlock projectHash={projectHash} taskId={taskId} />
                    </TabPanel>
                    <TabPanel value={'1'} sx={{p: 0}}>
                        <TaskAttachmentsListBlock projectHash={projectHash} taskId={taskId} />
                    </TabPanel>
                </React.Fragment>
            </FeatureCard>
        </TabContext>
    )
}

export default React.memo(RightCard)