import {ProgressBarParams} from "./progressBar.interface";
import Box from "@mui/material/Box";
import {LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {useTypedSelector} from "../../app/store";
import {getProgress} from "./progressBarSlice";

const ProgressBar = ({
    category,
    id
}: ProgressBarParams) => {

    const progress = useTypedSelector((state) => getProgress(state, category, id))

    if (progress === undefined) {
        return null;
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        color="secondary"
                        value={progress}
                        sx={{
                            '--LinearProgress-radius': '0px',
                            '--LinearProgress-progressThickness': '24px',
                            boxShadow: 'sm',
                            borderColor: 'neutral.500',
                        }}
                    />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${progress}%`}</Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default React.memo(ProgressBar)