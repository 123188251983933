import Form from "../../../components/form/Form";
import React, {useRef} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "mui-rff";
import Select from "../../../components/fields/select/Select";
import DateTimePicker from "../../../components/fields/datetimePicker/DateTimePicker";
import {AnyObject} from "react-final-form";
import {FormMeta, FormState} from "../../../components/form/form.interface";
import {useTranslation} from "react-i18next";
import {Signal} from "@preact/signals-react";
import FormStateControl from "../../../components/form/FormStateControl";
import {Task} from "../../../features/task/task.interface";
import {FormApi} from "final-form";
import FormSubmitButton from "../../../components/form/FormSubmitButton";
import {Save} from "@mui/icons-material";

type TaskHeaderFormProps = {
    onSubmit: (values: AnyObject, form: FormApi) => Promise<{}>,
    formMeta: FormMeta,
    data: Task,
    formsState: Signal<FormState[]>
}

const TaskHeaderForm = ({
    onSubmit,
    formMeta,
    data,
    formsState
}: TaskHeaderFormProps) => {
    const {t} = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);

    return (
        <Form
            onSubmit={onSubmit}
            formMeta={formMeta}
            initialValues={data}
            subscription={{}}
            render={({handleSubmit,}) => (
                <form style={{width: '100%', flexGrow: 1}} ref={formRef} onSubmit={handleSubmit}>
                    <FormStateControl name={'TaskHeader'} formRef={formRef} formsState={formsState} />
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                name={'name'}
                                className={'Mui-TitleField MuiInput-previewed'}
                                variant={"filled"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item container spacing={5} flexGrow={1}>
                            <Grid item xs={6}>
                                <DateTimePicker
                                    name={'startDate'}
                                    textFieldProps={{
                                        variant: "filled",
                                        placeholder: t('common.fieldPlaceholder') || ''
                                    }}
                                    label={t('task.startDate')}
                                    className={'MuiInput-previewed'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    formControlProps={{
                                        className: 'MuiInput-previewed'
                                    }}
                                    name={'status'}
                                    variant={"filled"}
                                    label={t('task.status') || ''}
                                    fullWidth
                                    data={(formMeta.fields?.['status']?.choices) || []}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} alignItems={"end"}>
                            <Grid item xs={6}>
                                <DateTimePicker
                                    name={'endDate'}
                                    textFieldProps={{
                                        variant: "filled",
                                        placeholder: t('common.fieldPlaceholder') || ''
                                    }}
                                    label={t('task.endDate')}
                                    className={'MuiInput-previewed'}
                                />
                            </Grid>
                            <Grid item xs={6} textAlign={'end'}>
                                <FormSubmitButton startIcon={<Save />} title={t('common.save')} formsState={formsState}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        />
    )
}

export default React.memo(TaskHeaderForm)