import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

const initialState = {
    loadedComponents: {}
} as { loadedComponents: {[key: string]: boolean} }

const slice = createSlice({
    name: 'waitForLoad',
    initialState,
    reducers: {
        setComponentIsLoaded: (state, {payload}: PayloadAction<{componentName: string, state: boolean}>) => {
            state.loadedComponents[payload.componentName] = payload.state
        },
    }
})

export const { setComponentIsLoaded } = slice.actions
export default slice.reducer

export const selectIsComponentLoaded = (state: RootState, componentName: string) => !!state.waitForLoad.loadedComponents?.[componentName]