import axios from 'axios';

export const axiousApi = axios.create({
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	},
	timeout: 5000,
	timeoutErrorMessage: 'error.serverNotAvailable',
	baseURL: `${process.env.REACT_APP_API_DOMAIN}/api/v1/`
});