import * as React from 'react';
import PropTypes from 'prop-types';
import { extractValidationProps, PickerViewRendererLookup } from '@mui/x-date-pickers/internals';
import { resolveComponentProps } from '@mui/base/utils';
import { refType } from '@mui/utils';
import { rangeValueManager } from './valueManagers';
import { DesktopDateRangePickerProps } from './DesktopDateRangePicker.types';
import { useDateRangePickerDefaultizedProps } from './shared';
import { renderDateRangeViewCalendar } from './dateRangeViewRenderers';
import { MultiInputDateRangeField } from './MultiInputDateRangeField';
import { useDesktopRangePicker } from './useDesktopRangePicker';
import { validateDateRange } from './validateDateRange';
import { DateRange } from './range';

type DesktopDateRangePickerComponent = (<TDate>(
    props: DesktopDateRangePickerProps<TDate> & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { propTypes?: any };

/**
 * Demos:
 *
 * - [DateRangePicker](https://mui.com/x/react-date-pickers/date-range-picker/)
 * - [Validation](https://mui.com/x/react-date-pickers/validation/)
 *
 * API:
 *
 * - [DesktopDateRangePicker API](https://mui.com/x/api/date-pickers/desktop-date-range-picker/)
 */
const DesktopDateRangePicker = React.forwardRef(function DesktopDateRangePicker<TDate>(
    inProps: DesktopDateRangePickerProps<TDate>,
    ref: React.Ref<HTMLDivElement>,
) {
    // Props with the default values common to all date time pickers
    const defaultizedProps = useDateRangePickerDefaultizedProps<
        TDate,
        DesktopDateRangePickerProps<TDate>
    >(inProps, 'MuiDesktopDateRangePicker');

    const viewRenderers: PickerViewRendererLookup<DateRange<TDate>, 'day', any, {}> = {
        day: renderDateRangeViewCalendar,
        ...defaultizedProps.viewRenderers,
    };

    const props = {
        ...defaultizedProps,
        viewRenderers,
        calendars: defaultizedProps.calendars ?? 2,
        views: ['day'] as const,
        openTo: 'day' as const,
        slots: {
            field: MultiInputDateRangeField,
            ...defaultizedProps.slots,
        },
        slotProps: {
            ...defaultizedProps.slotProps,
            field: (ownerState: any) => ({
                ...resolveComponentProps(defaultizedProps.slotProps?.field, ownerState),
                ...extractValidationProps(defaultizedProps),
                ref,
            }),
            toolbar: {
                hidden: true,
                ...defaultizedProps.slotProps?.toolbar,
            },
        },
    };

    const { renderPicker } = useDesktopRangePicker<TDate, 'day', typeof props>({
        props,
        valueManager: rangeValueManager,
        valueType: 'date',
        validator: validateDateRange,
    });

    return renderPicker();
}) as DesktopDateRangePickerComponent;

DesktopDateRangePicker.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    /**
     * If `true`, the main element is focused during the first mount.
     * This main element is:
     * - the element chosen by the visible view if any (i.e: the selected day on the `day` view).
     * - the `input` element if there is a field rendered.
     */
    autoFocus: PropTypes.bool,
    /**
     * The number of calendars to render on **desktop**.
     * @default 2
     */
    calendars: PropTypes.oneOf([1, 2, 3]),
    /**
     * Class name applied to the root element.
     */
    className: PropTypes.string,
    /**
     * If `true`, the popover or modal will close after submitting the full date.
     * @default `true` for desktop, `false` for mobile (based on the chosen wrapper and `desktopModeMediaQuery` prop).
     */
    closeOnSelect: PropTypes.bool,
    /**
     * Overridable components.
     * @default {}
     * @deprecated Please use `slots`.
     */
    components: PropTypes.object,
    /**
     * The props used for each component slot.
     * @default {}
     * @deprecated Please use `slotProps`.
     */
    componentsProps: PropTypes.object,
    /**
     * Position the current month is rendered in.
     * @default 1
     */
    currentMonthCalendarPosition: PropTypes.oneOf([1, 2, 3]),
    /**
     * Formats the day of week displayed in the calendar header.
     * @param {string} day The day of week provided by the adapter.  Deprecated, will be removed in v7: Use `date` instead.
     * @param {TDate} date The date of the day of week provided by the adapter.
     * @returns {string} The name to display.
     * @default (_day: string, date: TDate) => adapter.format(date, 'weekdayShort').charAt(0).toUpperCase()
     */
    dayOfWeekFormatter: PropTypes.func,
    /**
     * Default calendar month displayed when `value={[null, null]}`.
     */
    defaultCalendarMonth: PropTypes.any,
    /**
     * The initial position in the edited date range.
     * Used when the component is not controlled.
     * @default 'start'
     */
    defaultRangePosition: PropTypes.oneOf(['end', 'start']),
    /**
     * The default value.
     * Used when the component is not controlled.
     */
    defaultValue: PropTypes.arrayOf(PropTypes.any),
    /**
     * If `true`, after selecting `start` date calendar will not automatically switch to the month of `end` date.
     * @default false
     */
    disableAutoMonthSwitching: PropTypes.bool,
    /**
     * If `true`, the picker and text field are disabled.
     * @default false
     */
    disabled: PropTypes.bool,
    /**
     * If `true`, editing dates by dragging is disabled.
     * @default false
     */
    disableDragEditing: PropTypes.bool,
    /**
     * If `true`, disable values after the current date for date components, time for time components and both for date time components.
     * @default false
     */
    disableFuture: PropTypes.bool,
    /**
     * If `true`, today's date is rendering without highlighting with circle.
     * @default false
     */
    disableHighlightToday: PropTypes.bool,
    /**
     * If `true`, the open picker button will not be rendered (renders only the field).
     * @default false
     */
    disableOpenPicker: PropTypes.bool,
    /**
     * If `true`, disable values before the current date for date components, time for time components and both for date time components.
     * @default false
     */
    disablePast: PropTypes.bool,
    /**
     * If `true`, the week number will be display in the calendar.
     */
    displayWeekNumber: PropTypes.bool,
    /**
     * Calendar will show more weeks in order to match this value.
     * Put it to 6 for having fix number of week in Gregorian calendars
     * @default undefined
     */
    fixedWeekNumber: PropTypes.number,
    /**
     * Format of the date when rendered in the input(s).
     * Defaults to localized format based on the used `views`.
     */
    format: PropTypes.string,
    /**
     * Density of the format when rendered in the input.
     * Setting `formatDensity` to `"spacious"` will add a space before and after each `/`, `-` and `.` character.
     * @default "dense"
     */
    formatDensity: PropTypes.oneOf(['dense', 'spacious']),
    /**
     * Pass a ref to the `input` element.
     * Ignored if the field has several inputs.
     */
    inputRef: refType,
    /**
     * The label content.
     * Ignored if the field has several inputs.
     */
    label: PropTypes.node,
    /**
     * If `true`, calls `renderLoading` instead of rendering the day calendar.
     * Can be used to preload information and show it in calendar.
     * @default false
     */
    loading: PropTypes.bool,
    /**
     * Locale for components texts.
     * Allows overriding texts coming from `LocalizationProvider` and `theme`.
     */
    localeText: PropTypes.object,
    /**
     * Maximal selectable date.
     */
    maxDate: PropTypes.any,
    /**
     * Minimal selectable date.
     */
    minDate: PropTypes.any,
    /**
     * Callback fired when the value is accepted.
     * @template TValue The value type. Will be either the same type as `value` or `null`. Can be in `[start, end]` format in case of range value.
     * @param {TValue} value The value that was just accepted.
     */
    onAccept: PropTypes.func,
    /**
     * Callback fired when the value changes.
     * @template TValue The value type. Will be either the same type as `value` or `null`. Can be in `[start, end]` format in case of range value.
     * @template TError The validation error type. Will be either `string` or a `null`. Can be in `[start, end]` format in case of range value.
     * @param {TValue} value The new value.
     * @param {FieldChangeHandlerContext<TError>} context The context containing the validation result of the current value.
     */
    onChange: PropTypes.func,
    /**
     * Callback fired when the popup requests to be closed.
     * Use in controlled mode (see `open`).
     */
    onClose: PropTypes.func,
    /**
     * Callback fired when the error associated to the current value changes.
     * If the error has a non-null value, then the `TextField` will be rendered in `error` state.
     *
     * @template TValue The value type. Will be either the same type as `value` or `null`. Can be in `[start, end]` format in case of range value.
     * @template TError The validation error type. Will be either `string` or a `null`. Can be in `[start, end]` format in case of range value.
     * @param {TError} error The new error describing why the current value is not valid.
     * @param {TValue} value The value associated to the error.
     */
    onError: PropTypes.func,
    /**
     * Callback fired on month change.
     * @template TDate
     * @param {TDate} month The new month.
     */
    onMonthChange: PropTypes.func,
    /**
     * Callback fired when the popup requests to be opened.
     * Use in controlled mode (see `open`).
     */
    onOpen: PropTypes.func,
    /**
     * Callback fired when the range position changes.
     * @param {RangePosition} rangePosition The new range position.
     */
    onRangePositionChange: PropTypes.func,
    /**
     * Callback fired when the selected sections change.
     * @param {FieldSelectedSections} newValue The new selected sections.
     */
    onSelectedSectionsChange: PropTypes.func,
    /**
     * Control the popup or dialog open state.
     * @default false
     */
    open: PropTypes.bool,
    /**
     * The position in the currently edited date range.
     * Used when the component position is controlled.
     */
    rangePosition: PropTypes.oneOf(['end', 'start']),
    readOnly: PropTypes.bool,
    /**
     * If `true`, disable heavy animations.
     * @default `@media(prefers-reduced-motion: reduce)` || `navigator.userAgent` matches Android <10 or iOS <13
     */
    reduceAnimations: PropTypes.bool,
    /**
     * The date used to generate the new value when both `value` and `defaultValue` are empty.
     * @default The closest valid date-time using the validation props, except callbacks like `shouldDisable<...>`.
     */
    referenceDate: PropTypes.any,
    /**
     * Component displaying when passed `loading` true.
     * @returns {React.ReactNode} The node to render when loading.
     * @default () => "..."
     */
    renderLoading: PropTypes.func,
    /**
     * The currently selected sections.
     * This prop accept four formats:
     * 1. If a number is provided, the section at this index will be selected.
     * 2. If an object with a `startIndex` and `endIndex` properties are provided, the sections between those two indexes will be selected.
     * 3. If a string of type `FieldSectionType` is provided, the first section with that name will be selected.
     * 4. If `null` is provided, no section will be selected
     * If not provided, the selected sections will be handled internally.
     */
    selectedSections: PropTypes.oneOfType([
        PropTypes.oneOf([
            'all',
            'day',
            'hours',
            'meridiem',
            'minutes',
            'month',
            'seconds',
            'weekDay',
            'year',
        ]),
        PropTypes.number,
        PropTypes.shape({
            endIndex: PropTypes.number.isRequired,
            startIndex: PropTypes.number.isRequired,
        }),
    ]),
    /**
     * Disable specific date.
     *
     * Warning: This function can be called multiple times (e.g. when rendering date calendar, checking if focus can be moved to a certain date, etc.). Expensive computations can impact performance.
     *
     * @template TDate
     * @param {TDate} day The date to test.
     * @param {string} position The date to test, 'start' or 'end'.
     * @returns {boolean} Returns `true` if the date should be disabled.
     */
    shouldDisableDate: PropTypes.func,
    /**
     * If `true`, days outside the current month are rendered:
     *
     * - if `fixedWeekNumber` is defined, renders days to have the weeks requested.
     *
     * - if `fixedWeekNumber` is not defined, renders day to fill the first and last week of the current month.
     *
     * - ignored if `calendars` equals more than `1` on range pickers.
     * @default false
     */
    showDaysOutsideCurrentMonth: PropTypes.bool,
    /**
     * The props used for each component slot.
     * @default {}
     */
    slotProps: PropTypes.object,
    /**
     * Overridable component slots.
     * @default {}
     */
    slots: PropTypes.object,
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    /**
     * Choose which timezone to use for the value.
     * Example: "default", "system", "UTC", "America/New_York".
     * If you pass values from other timezones to some props, they will be converted to this timezone before being used.
     * @see See the {@link https://mui.com/x/react-date-pickers/timezone/ timezones documention} for more details.
     * @default The timezone of the `value` or `defaultValue` prop is defined, 'default' otherwise.
     */
    timezone: PropTypes.string,
    /**
     * The selected value.
     * Used when the component is controlled.
     */
    value: PropTypes.arrayOf(PropTypes.any),
    /**
     * Define custom view renderers for each section.
     * If `null`, the section will only have field editing.
     * If `undefined`, internally defined view will be the used.
     */
    viewRenderers: PropTypes.shape({
        day: PropTypes.func,
    }),
} as any;

export { DesktopDateRangePicker };