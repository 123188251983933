import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {FormMeta} from "../../components/form/form.interface";
import {taskApi} from "./taskApi";

const initialState = {
    forms: undefined,
} as { forms?: FormMeta[] }

const slice = createSlice({
    name: 'taskMeta',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(taskApi.endpoints.taskMetadata.matchFulfilled, (state, action) => {
                state.forms = action.payload
            })
    },
})

export default slice.reducer

export const selectTaskFormMeta = (state: RootState, formName: string) => state.taskMeta.forms?.find(form => form.name === formName)