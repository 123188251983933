import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import {enqueueSnackbar} from "notistack";
import ProjectMembersListBlock from "./parts/ProjectMembersListBlock";
import {
    useProjectMetadataQuery,
    useUpdateProjectMutation
} from "../../features/project/projectApi";
import {useTypedSelector} from "../../app/store";
import {selectProjectFormMeta} from "../../features/project/projectMetaSlice";
import {getOnlyDirtyValues, processSubmitErrors} from "../../components/form/formUtils";
import {AnyObject} from "react-final-form";
import AppLoader from "../../components/appLoader/AppLoader";
import {getProject} from "../../features/project/projectSlice";
import ProjectTasksListGrid from "./parts/ProjectTasksListGrid";
import {AccountTree, People, Save, Settings, Task as TaskIcon} from "@mui/icons-material";
import FeatureCard from "../../components/featureCard/FeatureCard";
import FeatureCardHeader from "../../components/featureCard/FeatureCardHeader";
import {Card, Container, Link, Tab} from "@mui/material";
import theme from "../../app/theme";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import TasksProgressBlock from "./parts/TasksProgressBlock";
import ProjectHeaderForm from "./parts/ProjectHeaderForm";
import FormSubmitButton from "../../components/form/FormSubmitButton";
import {signal} from "@preact/signals-react";
import {FormState} from "../../components/form/form.interface";
import ProjectDetailsForm from "./parts/ProjectDetailsForm";
import {FormApi} from "final-form";

const ProjectPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const formsState = signal<FormState[]>([])
    const [selectedTab, setSelectedTab] = React.useState<string>('0');

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
    };

    if (id === undefined) {
        navigate('/');
        enqueueSnackbar(t('project.notFound'), {variant: "error"})
        return null;
    }

    const [updateProject] = useUpdateProjectMutation()
    const projectDetailsFormMeta = useTypedSelector((state) => selectProjectFormMeta(state, 'project_edit'))
    const {isLoading: isLoadingProjectMeta , isError: isErrorProjectMeta} = useProjectMetadataQuery()
    const {project, isLoading: isLoadingProject, isError: isErrorProject} = getProject(id)

    if (isLoadingProjectMeta || isLoadingProject || !project || !projectDetailsFormMeta) {
        return <AppLoader/>
    }

    if (isErrorProject || isErrorProjectMeta) {
        throw new Error('Can\'t load the project')
    }

    const onSubmitProjectDetails = async (_: AnyObject, form: FormApi) => {
        let errors = {}
        let values = getOnlyDirtyValues(form)

        try {
            await updateProject({projectHash: id, data: values}).unwrap()
            enqueueSnackbar(t('project.successfullySaved'), {variant: "success", preventDuplicate: true})
        } catch (e) {
            errors = processSubmitErrors(e, projectDetailsFormMeta)
        }

        return errors
    }

    return (
        <Container maxWidth={"lg"} sx={{pr: {xs: 0}}}>
            <Grid container direction={'column'} spacing={2} sx={{flexGrow: 1, width: '100%'}}>
                <Grid item>
                    <Card sx={{pr: {xs: 3, md: 5}, pl: {xs: 7, md: 5}, py: 2}}>
                        <Grid item container rowSpacing={1}>
                            <Grid item xs={12}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => navigate('/')}
                                >{'< ' + t('pages.projects')}</Link>
                            </Grid>
                            <Grid item xs={12}>
                                <ProjectHeaderForm
                                    onSubmit={onSubmitProjectDetails}
                                    formMeta={projectDetailsFormMeta}
                                    data={project}
                                    formsState={formsState}
                                />
                            </Grid>
                            <Grid item container alignItems={"end"}>
                                <Grid item xs={8} md={10}>
                                    <TasksProgressBlock project={project} />
                                </Grid>
                                <Grid item xs={4} md={2} textAlign={'end'}>
                                    <FormSubmitButton startIcon={<Save />} title={t('common.save')} formsState={formsState}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item sx={{flex: 1, flexGrow: 1, width: '100%'}}>
                    <TabContext value={selectedTab}>
                        <Card>
                            <TabList
                                TabIndicatorProps={{
                                    hidden: true
                                }}
                                sx={{
                                    '& button.Mui-selected': {
                                        borderBottom: `2px solid ${theme.palette.primary.main}`
                                    }
                                }}
                                centered
                                onChange={handleTabChange}
                            >
                                <Tab value={'0'} iconPosition={"start"} icon={<AccountTree/>} label={t("pages.project")} sx={{mr: 2}} />
                                <Tab value={'1'} iconPosition={"start"} icon={<TaskIcon/>} label={t('project.tasksList')} />
                            </TabList>
                        </Card>
                        <TabPanel value={'0'} sx={{px: 0, py: theme.spacing(2)}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <FeatureCard maxHeight header={
                                        <FeatureCardHeader icon={<Settings color="primary" />} title={t("common.details")} />
                                    }>
                                        <ProjectDetailsForm
                                            onSubmit={onSubmitProjectDetails}
                                            formMeta={projectDetailsFormMeta}
                                            data={project}
                                            formsState={formsState}
                                        />
                                    </FeatureCard>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <FeatureCard maxHeight header={
                                        <FeatureCardHeader icon={<People color="primary" />} title={t('project.members')} />
                                    }>
                                        <ProjectMembersListBlock projectHash={id} />
                                    </FeatureCard>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={'1'} sx={{width: '100%', px: 0, py: theme.spacing(2)}}>
                            <ProjectTasksListGrid projectHash={id} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ProjectPage;
