import React, {useEffect, useState} from "react";
import {AdvancedDataGridProps, DataGridState} from "./dataGrid.interface";
import {useTranslation} from "react-i18next";
import {
    getGridNumericOperators,
    getGridSingleSelectOperators, getGridStringOperators,
    GridFilterInputMultipleSingleSelect,
    GridFilterInputValue,
    GridFilterItem,
    GridFilterModel,
    GridRowParams,
    GridRowsProp,
    GridSortItem,
    GridSortModel,
} from "@mui/x-data-grid";
import {GridPaginationModel} from "@mui/x-data-grid/models/gridPaginationProps";
import {displayErrors} from "../../service/errorService";
import {dataGridStyles} from "./dataGridStyles";
import NoRowsOverlay from "./NoRowsOverlay";
import {DataGridColumnHeaders} from "./overrides/DataGridColumnHeaders";
import {DataGrid} from "./overrides/DataGridRaw";
import DateTimeRangeFilter from "./DateTimeRangeFilter";

const AdvancedDataGrid = <EntityType, >({
    defaultSort,
    gridColumns,
    loadList,
    getGridRow,
    onRowClick,
    noRowsOverlayProps,
    onComponentIsLoaded,
    enableFilters,
    gridApiRef
}: AdvancedDataGridProps<EntityType>) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [dataGridState, setDataGridState] = useState<DataGridState>({
        isLoading: false,
        data: [],
        total: 0,
        pagination: {
            page: 0,
            pageSize: 10
        },
        sort: defaultSort,
        filters: {
            items: []
        }
    })
    const {t} = useTranslation();

    gridColumns.forEach((element, index) => {
        gridColumns[index] = {...element, ...{
                headerName: t(element.headerName || '') as string,
                align: "center",
                headerAlign: "center",
                headerClassName: 'MuiDataGrid-header',
                disableColumnMenu: true,
        }};

        if (element.filterable === false) {
            return;
        }

        if (element.type === 'dateTime') {
            gridColumns[index]['minWidth'] = 300
            gridColumns[index]['filterOperators'] = getGridNumericOperators()
                .filter((operator) => operator.value !== 'is')
                .map((operator) => ({
                    ...operator,
                    InputComponent: operator.InputComponent
                        ? DateTimeRangeFilter
                        : undefined,
                }))
        } else if (element.type === 'singleSelect') {
            gridColumns[index]['minWidth'] = 200
            gridColumns[index]['filterOperators'] = getGridSingleSelectOperators()
                .filter((operator) => operator.value !== "is")
                .map((operator) => ({
                    ...operator,
                    InputComponentProps: {
                        ...operator.InputComponentProps,
                        variant: 'outlined',
                        sx: { '& legend': { display: 'none' }, '& fieldset': { top: 0 },}
                    },
                    InputComponent: GridFilterInputMultipleSingleSelect,
                }))
        } else if (element.type === 'string') {
            gridColumns[index]['filterOperators'] = getGridStringOperators()
                .filter((operator) => operator.value === "contains")
                .map((operator) => ({
                    ...operator,
                    InputComponentProps: {
                        ...operator.InputComponentProps,
                        variant: 'outlined'
                    },
                    InputComponent: GridFilterInputValue,
                }))
        }
    });

    useEffect(() => {
        const init = async () => {
            void await fetchData(dataGridState.pagination, dataGridState.sort, dataGridState.filters);

            onComponentIsLoaded()
            setIsLoaded(true)
        }

        void init()
    }, []);


    const fetchData = async (paginationModel: GridPaginationModel, sortModel: GridSortModel, filterModel: GridFilterModel) => {
        setDataGridState(old => ({
            ...old,
            isLoading: true,
            sort: sortModel,
            pagination: paginationModel,
            filters: filterModel
        }))

        try {
            let sort: { [key: string]: string } = {};
            sortModel.forEach((value: GridSortItem) =>  {
                sort[value.field] = (value.sort as string).toUpperCase()
            })

            let filters: { [key: string]: string } = {};
            filterModel.items.forEach((value: GridFilterItem) =>  {
                filters[value.field] = value.value
            })

            const list = await loadList({
                currentPage: paginationModel.page + 1,
                maxPerPage: paginationModel.pageSize,
                sort: sort,
                filters: filters
            })

            setDataGridState(old => ({
                ...old,
                isLoading: false,
                data: list.collection,
                total: list.pager.total,
            }))
        } catch (e) {
            displayErrors(e);
        }
    }

    const getGridRows = (): GridRowsProp => {
        return dataGridState.data.map(getGridRow)
    }

    const onPaginationChange = (model: GridPaginationModel) => {
        void fetchData(model, dataGridState.sort, dataGridState.filters)
    }

    const onSortChange = (model: GridSortModel) => {
        void fetchData(dataGridState.pagination, model, dataGridState.filters)
    }

    const onFilterChange = (model: GridFilterModel) => {
        void fetchData(dataGridState.pagination, dataGridState.sort, model)
    }

    if (!isLoaded) {
        return null;
    }

    return (
        <DataGrid
            apiRef={gridApiRef}
            autoHeight
            initialState={{
                sorting: {
                    sortModel: dataGridState.sort
                },
                pagination: {
                    paginationModel: dataGridState.pagination
                }
            }}
            rows={getGridRows()}
            rowCount={dataGridState.total}
            pagination
            loading={dataGridState.isLoading}
            paginationMode={"server"}
            onPaginationModelChange={onPaginationChange}
            onSortModelChange={onSortChange}
            onRowClick={(event: GridRowParams) => onRowClick(dataGridState.data[event.id as number])}
            sortingMode={"server"}
            pageSizeOptions={[10, 25, 50]}
            columns={gridColumns}
            hideFooter={dataGridState.data.length < 1}
            {...(enableFilters ? {
                filterMode: "server",
                onFilterModelChange: onFilterChange,
                unstable_headerFilters: true,
                disableColumnFilter: true
            } : {})}
            sx={{...dataGridStyles}}
            slots={{
                noRowsOverlay: NoRowsOverlay,
                columnHeaders: DataGridColumnHeaders,
            }}
            slotProps={{
                noRowsOverlay: noRowsOverlayProps
            }}
        />
    );
}

export default React.memo(AdvancedDataGrid) as typeof AdvancedDataGrid