import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useTypedSelector} from "../../app/store";
import CrudDialog from "../../components/crudDialog/CrudDialog";
import { TextField, Select } from 'mui-rff';
import {processSubmitErrors} from "../../components/form/formUtils";
import {CreateTaskDialogParams} from "./task.interface";
import {useCreateTaskMutation, useTaskMetadataQuery} from "./taskApi";
import {selectTaskFormMeta} from "./taskMetaSlice";

export default function CreateTaskDialog({
    open,
    setOpen,
    projectHash,
}: CreateTaskDialogParams) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {isLoading, isError} = useTaskMetadataQuery({projectHash: projectHash})
    const formMeta = useTypedSelector((state) => selectTaskFormMeta(state, 'task_create'))
    const [createTask] = useCreateTaskMutation()

    if (isLoading) {
        return null;
    }

    if (!formMeta || isError) {
        throw new Error('Can\'t load task_create form meta')
    }

    const onSubmit = async (values: {name?: string, type?: string}) => {
        let errors = {}

        try {
            const task = await createTask({projectHash: projectHash, data: values}).unwrap()
            navigate(`/project/${projectHash}/task/${task.number}`);
        } catch (e) {
            errors = processSubmitErrors(e, formMeta)
        }

        return errors
    }

    return (
        <CrudDialog
            open={open}
            setOpen={setOpen}
            title={'createTask.newTask'}
            submitButtonLabel={'common.create'}
            onSubmit={onSubmit}
            formMeta={formMeta}
            content={() => (
                <React.Fragment>
                    <TextField
                        name={'name'}
                        variant={"standard"}
                        label={t('task.name')}
                        fullWidth
                    />
                    <Select
                        name={'type'}
                        variant={"standard"}
                        label={t('task.type') || ''}
                        fullWidth
                        data={(formMeta.fields?.['type']?.choices) || []}
                    />
                </React.Fragment>
            )}
        />
    );
}