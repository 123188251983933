import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {FormMeta} from "../../components/form/form.interface";
import {projectApi} from "./projectApi";

const initialState = {
    forms: undefined,
} as { forms?: FormMeta[] }

const slice = createSlice({
    name: 'projectMeta',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(projectApi.endpoints.projectMetadata.matchFulfilled, (state, action) => {
                state.forms = action.payload
            })
    },
})

export default slice.reducer

export const selectProjectFormMeta = (state: RootState, formName: string) => state.projectMeta.forms?.find(form => form.name === formName)