import {FormsMetaResponse, GetResponse, ListResponse} from "../../api/apiResponse.interface";
import {api} from "../../api/api";
import {ListRequest} from "../../api/apiRequest.interface";
import {Project} from "./project.interface";

export const projectApi = api.injectEndpoints({
    endpoints: (builder) => ({
        projectsList: builder.query<ListResponse, ListRequest>({
            query: (listState) => ({
                url: 'project/list',
                method: 'POST',
                body: {
                    projects: listState
                },
            }),
            keepUnusedDataFor: 2,
            transformResponse: (response: {status: number, data: ListResponse}) => {
                return response.data
            }
        }),
        projectMetadata: builder.query<FormsMetaResponse, void>({
            providesTags: ['Metadata'],
            query: () => ({
                url: 'project/metadata',
                method: 'GET',
            }),
            transformResponse: (response: {status: number, data: {forms: FormsMetaResponse}}) => {
                return response.data.forms
            }
        }),
        getProject: builder.query<Project, {projectHash: string}>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/get`,
                method: 'GET',
            }),
            transformResponse: (response: {status: number, data: GetResponse}) => {
                return response.data.entity.project as Project
            }
        }),
        createProject: builder.mutation<Project, {name?: string, type?: string}>({
            query: (queryData) => ({
                url: 'project/create',
                method: 'POST',
                body: queryData,
            }),
            transformResponse: (response: {status: number, data: GetResponse}) => {
                return response.data.entity.project as Project
            }
        }),
        updateProject: builder.mutation<Project, {projectHash: string, data: {[key: string]: any }}>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/edit`,
                method: 'PUT',
                body: queryData.data,
            }),
            transformResponse: (response: {status: number, data: GetResponse}) => {
                return response.data.entity.project as Project
            }
        }),
    }),
})

export const {
    useLazyProjectsListQuery,
    useProjectMetadataQuery,
    useGetProjectQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
} = projectApi

export const {
    endpoints: { projectsList, projectMetadata, getProject, createProject, updateProject },
} = projectApi