import {FeatureCardHeaderProps} from "./featureCard.interface";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

const FeatureCardHeader = ({ icon, title }: FeatureCardHeaderProps) => {

    return (
        <Grid container spacing={1} alignItems="center" >
            <Grid item display={"flex"} alignItems={"center"}>{icon}</Grid>
            <Grid item>
                <Typography variant="h6">{title}</Typography>
            </Grid>
        </Grid>
    )
}

export default React.memo(FeatureCardHeader)