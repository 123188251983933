import React from 'react';
import ErrorRenderer from "./ErrorRenderer";
import {withTranslation} from "react-i18next";

class ErrorBoundary extends React.Component<any, {hasError: boolean}> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <ErrorRenderer><h1>{this.props.t('error.general')}</h1></ErrorRenderer>
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);
