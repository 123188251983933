import {api} from "../../api/api";
import {TaskMember} from "./task.interface";

const transformTaskMembersResponse = (response: {
    status: number
    data: TaskMember[]
}) => response.data

export const taskMembersApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createTaskMember: builder.mutation<TaskMember[], {
            projectHash: string,
            taskId: number,
            data: { [key: string]: any }
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/${queryData.taskId}/member/create`,
                method: 'POST',
                body: queryData.data,
            }),
            transformResponse: transformTaskMembersResponse
        }),

        editTaskMember: builder.mutation<TaskMember[], {
            projectHash: string,
            taskId: number,
            memberId: number,
            data: { [key: string]: any }
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/${queryData.taskId}/member/${queryData.memberId}/edit`,
                method: 'PUT',
                body: queryData.data,
            }),
            transformResponse: transformTaskMembersResponse
        }),
        deleteTaskMember: builder.mutation<TaskMember[], {
            projectHash: string,
            taskId: number,
            memberId: number,
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/${queryData.taskId}/member/${queryData.memberId}/delete`,
                method: 'DELETE',
            }),
            transformResponse: transformTaskMembersResponse
        }),
    }),
})

export const {
    useCreateTaskMemberMutation,
    useEditTaskMemberMutation,
    useDeleteTaskMemberMutation,
} = taskMembersApi

export const {
    endpoints: { createTaskMember, deleteTaskMember, editTaskMember },
} = taskMembersApi