import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    GridAddIcon,
} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import CreateTaskDialog from "./CreateTaskDialog";
import {AddNewTaskButtonParams} from "./task.interface";

const AddNewTaskButton = ({
    buttonRef,
    projectHash
}: AddNewTaskButtonParams) => {
    const [createDialogState, setCreateDialogState] = useState<boolean>(false)
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <CreateTaskDialog projectHash={projectHash} open={createDialogState} setOpen={setCreateDialogState} />
            <Button ref={buttonRef} variant="contained" color={"primary"} onClick={() => setCreateDialogState(true)} startIcon={<GridAddIcon />}>
                {t('tasksList.addNew')}
            </Button>
        </React.Fragment>
    )
}

export default React.memo(AddNewTaskButton)