import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {FormMeta} from "../../components/form/form.interface";
import {projectMembersApi} from "./projectMembersApi";

const initialState = {
    forms: undefined,
} as { forms?: FormMeta[] }

const slice = createSlice({
    name: 'projectMembersMeta',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(projectMembersApi.endpoints.projectMembersMetadata.matchFulfilled, (state, action) => {
                state.forms = action.payload
            })
    },
})

export default slice.reducer

export const selectProjectMembersFormMeta = (state: RootState, formName: string) => state.projectMembersMeta.forms?.find(form => form.name === formName)