import React from "react";
import {DataGridToolbarProps} from "./dataGrid.interface";
import Box from "@mui/material/Box";
import DataGridFiltersButton from "./DataGridFiltersButton";
import TipButton from "../tipButton/TipButton";
import {Trans} from "react-i18next";
import {useMediaQuery} from "@mui/material";
import theme from "../../app/theme";

const DataGridToolbar = ({
   gridApiRef
}: DataGridToolbarProps) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   return (
       <Box sx={{display: "flex", flexDirection: "row", columnGap: "20px"}}>
           {!isMobile && (<TipButton hint={<Trans i18nKey="grid.usageHint"/>}/>)}
          <DataGridFiltersButton gridApiRef={gridApiRef} />
       </Box>
   )
}

export default React.memo(DataGridToolbar)