import Form from "../../../components/form/Form";
import React, {useEffect, useRef} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "mui-rff";
import {AnyObject} from "react-final-form";
import {FormMeta, FormState} from "../../../components/form/form.interface";
import {Project} from "../../../features/project/project.interface";
import {useTranslation} from "react-i18next";
import {Signal} from "@preact/signals-react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import FormStateControl from "../../../components/form/FormStateControl";
import {FormApi} from "final-form";

type ProjectHeaderFormProps = {
    onSubmit: (values: AnyObject, form: FormApi) => Promise<{}>,
    formMeta: FormMeta,
    data: Project,
    formsState: Signal<FormState[]>
}

const ProjectDetailsForm = ({
    onSubmit,
    formMeta,
    data,
    formsState
}: ProjectHeaderFormProps) => {
    const {t} = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);

    // Textarea has a bug and can't be resized it's a workaround till, maybe mui will fix this in the future....
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            formMeta={formMeta}
            initialValues={data}
            subscription={{}}
            render={({handleSubmit,}) => (
                <form style={{width: '100%', flexGrow: 1}} ref={formRef} onSubmit={handleSubmit}>
                    <FormStateControl name={'ProjectDetails'} formRef={formRef} formsState={formsState} />
                    <List sx={{flex: 1}}>
                        <ListItem>
                            <TextField
                                name={'description'}
                                variant={"filled"}
                                label={t('project.description')}
                                className={'MuiInput-previewed'}
                                placeholder={t('common.fieldPlaceholder') || ''}
                                fullWidth
                                multiline
                            />
                        </ListItem>
                        <ListItem>
                            <TextField
                                name={'budget'}
                                variant={"filled"}
                                type={"number"}
                                label={t('project.budget')}
                                className={'MuiInput-previewed'}
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    },
                                    startAdornment:
                                        <InputAdornment position={"end"} className={'alwaysShow'} sx={{mr: 1, ml: 0}}>
                                            <Typography>{t(String(data.currency || ''))}</Typography>
                                        </InputAdornment>
                                }}
                                fullWidth
                            />
                        </ListItem>
                        <ListItem>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'location'}
                                        variant={"filled"}
                                        label={t('project.location')}
                                        className={'MuiInput-previewed'}
                                        placeholder={t('common.fieldPlaceholder') || ''}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'peopleNumber'}
                                        variant={"filled"}
                                        type={"number"}
                                        label={t('project.peopleNumber')}
                                        className={'MuiInput-previewed'}
                                        placeholder={t('common.fieldPlaceholder') || ''}
                                        fullWidth
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </form>
            )}
        />
    )
}

export default React.memo(ProjectDetailsForm)