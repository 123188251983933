import {TabContext, TabList, TabPanel} from "@mui/lab";
import FeatureCard from "../../../components/featureCard/FeatureCard";
import theme from "../../../app/theme";
import React from "react";
import {Tab} from "@mui/material";
import {ChecklistRtl, Settings} from "@mui/icons-material";
import TaskDetailsForm from "./TaskDetailsForm";
import TaskAgendaForm from "./TaskAgendaForm";
import {useTranslation} from "react-i18next";
import {AnyObject} from "react-final-form";
import {FormApi} from "final-form";
import {FormMeta, FormState} from "../../../components/form/form.interface";
import {Task} from "../../../features/task/task.interface";
import {Signal} from "@preact/signals-react";

type LeftCardProps = {
    onSubmit: (values: AnyObject, form: FormApi) => Promise<{}>,
    formMeta: FormMeta,
    task: Task,
    formsState: Signal<FormState[]>
}

const LeftCard = ({
    onSubmit,
    formMeta,
    task,
    formsState
}: LeftCardProps) => {
    const [selectedTab, setSelectedTab] = React.useState<string>('0');
    const {t} = useTranslation();

    return (
        <TabContext value={selectedTab}>
            <FeatureCard maxHeight sx={{
                    '& .FeatureCardHeader': {
                        py: 0
                    },
                }}
                 header={
                     <TabList
                         TabIndicatorProps={{
                             hidden: true
                         }}
                         sx={{
                             '& button.Mui-selected': {
                                 borderBottom: `2px solid ${theme.palette.primary.main}`
                             }
                         }}
                         onChange={(_: React.SyntheticEvent, newValue: string) => setSelectedTab(newValue)}
                     >
                         <Tab value={'0'} iconPosition={"start"} icon={<Settings/>} label={t("common.details")} sx={{mr: 2}} />
                         <Tab value={'1'} iconPosition={"start"} icon={<ChecklistRtl/>} label={t("task.agenda")} />
                     </TabList>
                 }
            >
                <React.Fragment>
                    <TabPanel value={'0'} sx={{p: 0}}>
                        <TaskDetailsForm
                            onSubmit={onSubmit}
                            formMeta={formMeta}
                            formsState={formsState}
                            data={task}
                        />
                    </TabPanel>
                    <TabPanel value={'1'} sx={{p: 0}}>
                        <TaskAgendaForm
                            onSubmit={onSubmit}
                            formMeta={formMeta}
                            formsState={formsState}
                            data={task}
                        />
                    </TabPanel>
                </React.Fragment>
            </FeatureCard>
        </TabContext>
    )
}

export default React.memo(LeftCard)