import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {Task, TaskAttachment, TaskMember, TasksMap} from "./task.interface";
import {taskApi} from "./taskApi";
import {taskMembersApi} from "./taskMembersApi";
import {taskAttachmentsApi} from "./taskAttachmentsApi";

const initialState = {} as {
    [projectHash: string]: TasksMap
}

const slice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        addTempAttachment: (state, action: PayloadAction<{projectHash: string, taskId: number, attachment: TaskAttachment}>) => {
            state[action.payload.projectHash][action.payload.taskId].attachments.push(action.payload.attachment);
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(taskApi.endpoints.createTask.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.payload.number]: action.payload
                }
            })
            .addMatcher(taskApi.endpoints.updateTask.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.payload.number]: action.payload
                }
            })
            .addMatcher(taskApi.endpoints.getTask.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.payload.number]: action.payload
                }
            })
            .addMatcher(taskMembersApi.endpoints.createTaskMember.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.meta.arg.originalArgs.taskId]: {
                        ...state[action.meta.arg.originalArgs.projectHash][action.meta.arg.originalArgs.taskId],
                        assignedMembers: action.payload
                    }
                }
            })
            .addMatcher(taskMembersApi.endpoints.editTaskMember.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.meta.arg.originalArgs.taskId]: {
                        ...state[action.meta.arg.originalArgs.projectHash][action.meta.arg.originalArgs.taskId],
                        assignedMembers: action.payload
                    }
                }
            })
            .addMatcher(taskMembersApi.endpoints.deleteTaskMember.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.meta.arg.originalArgs.taskId]: {
                        ...state[action.meta.arg.originalArgs.projectHash][action.meta.arg.originalArgs.taskId],
                        assignedMembers: action.payload
                    }
                }
            })
            .addMatcher(taskAttachmentsApi.endpoints.createTaskAttachment.matchFulfilled, (state, action) => {
                const {projectHash, taskId, tempName} = action.meta.arg.originalArgs
                const tempAttachments = (state[projectHash]?.[taskId]?.attachments || []).filter(
                    (attachment) => attachment.tempName && attachment.tempName !== tempName
                )

                state[projectHash] = {
                    ...state[projectHash],
                    [taskId]: {
                        ...state[projectHash][taskId],
                        attachments: [
                            ...action.payload,
                            ...tempAttachments
                        ]
                    }
                }
            })
            .addMatcher(taskAttachmentsApi.endpoints.editTaskAttachment.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.meta.arg.originalArgs.taskId]: {
                        ...state[action.meta.arg.originalArgs.projectHash][action.meta.arg.originalArgs.taskId],
                        attachments: action.payload
                    }
                }
            })
            .addMatcher(taskAttachmentsApi.endpoints.deleteTaskAttachment.matchFulfilled, (state, action) => {
                state[action.meta.arg.originalArgs.projectHash] = {
                    ...state[action.meta.arg.originalArgs.projectHash],
                    [action.meta.arg.originalArgs.taskId]: {
                        ...state[action.meta.arg.originalArgs.projectHash][action.meta.arg.originalArgs.taskId],
                        attachments: action.payload
                    }
                }
            })
    },
})

export const { addTempAttachment } = slice.actions

export default slice.reducer

export const getTask = (state: RootState, projectHash: string, taskId: number): Task | undefined => state.tasks?.[projectHash]?.[taskId]
export const getTaskMembers = (state: RootState, projectHash: string, taskId: number): TaskMember[] | undefined => state.tasks?.[projectHash]?.[taskId].assignedMembers
export const getTaskAttachments = (state: RootState, projectHash: string, taskId: number): TaskAttachment[] | undefined => state.tasks?.[projectHash]?.[taskId].attachments