import React from 'react';
import {ErrorBoundaryProps} from "./error.interface";

const ErrorRenderer = ({children}: ErrorBoundaryProps) => {
    return (
        <div>
            {children}
        </div>
    );
}

export default ErrorRenderer;
