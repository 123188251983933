import {api} from "../../api/api";
import {FormsMetaResponse} from "../../api/apiResponse.interface";
import {ProjectMember} from "./project.interface";

const transformProjectMembersResponse = (response: {
    status: number
    data:  ProjectMember[]
}) => response.data

export const projectMembersApi = api.injectEndpoints({
    endpoints: (builder) => ({
        projectMembersMetadata: builder.query<FormsMetaResponse, {
            projectHash: string
        }>({
            providesTags: ['Metadata'],
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/member/metadata`,
                method: 'GET',
            }),
            transformResponse: (response: {status: number, data: {forms: FormsMetaResponse}}) => {
                return response.data.forms
            }
        }),
        createProjectMember: builder.mutation<ProjectMember[], {
            projectHash: string,
            data: { [key: string]: any }
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/member/create`,
                method: 'POST',
                body: queryData.data,
            }),
            transformResponse: transformProjectMembersResponse
        }),

        editProjectMember: builder.mutation<ProjectMember[], {
            projectHash: string,
            memberId: number,
            data: { [key: string]: any }
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/member/${queryData.memberId}/edit`,
                method: 'PUT',
                body: queryData.data,
            }),
            transformResponse: transformProjectMembersResponse
        }),
        deleteProjectMember: builder.mutation<ProjectMember[], {
            projectHash: string,
            memberId: number,
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/member/${queryData.memberId}/delete`,
                method: 'DELETE',
            }),
            transformResponse: transformProjectMembersResponse
        }),
    }),
})

export const {
    useProjectMembersMetadataQuery,
    useCreateProjectMemberMutation,
    useEditProjectMemberMutation,
    useDeleteProjectMemberMutation,
} = projectMembersApi

export const {
    endpoints: { createProjectMember, editProjectMember, deleteProjectMember },
} = projectMembersApi