import * as React from 'react';
import { useThemeProps } from '@mui/material/styles';
import {
    GRID_DEFAULT_LOCALE_TEXT,
    DATA_GRID_PROPS_DEFAULT_VALUES,
    GridValidRowModel,
    DataGridProps,
    GridSlotsComponent,
} from '@mui/x-data-grid';
import {
    computeSlots,
    DATA_GRID_DEFAULT_SLOTS_COMPONENTS,
    UncapitalizeObjectKeys,
    useProps
} from '@mui/x-data-grid/internals';
import {DataGridForcedPropsKey, DataGridProcessedProps} from '@mui/x-data-grid/models/props/DataGridProps';
import {uncapitalizeObjectKeys} from "@mui/x-data-grid/internals/utils";

const DATA_GRID_FORCED_PROPS: { [key in DataGridForcedPropsKey]?: DataGridProcessedProps[key] } = {
    signature: 'DataGrid',
};

const defaultSlots = uncapitalizeObjectKeys(DATA_GRID_DEFAULT_SLOTS_COMPONENTS)!;

export const useDataGridProps = <R extends GridValidRowModel>(inProps: DataGridProps<R>) => {
    const [components, componentsProps, themedProps] = useProps(
        useThemeProps({
            props: inProps,
            name: 'MuiDataGrid',
        }),
    );

    const localeText = React.useMemo(
        () => ({ ...GRID_DEFAULT_LOCALE_TEXT, ...themedProps.localeText }),
        [themedProps.localeText],
    );

    const slots = React.useMemo<UncapitalizeObjectKeys<GridSlotsComponent>>(
        () =>
            computeSlots<GridSlotsComponent>({
                defaultSlots,
                slots: themedProps.slots,
                components,
            }),
        [components, themedProps.slots],
    );

    return React.useMemo<DataGridProcessedProps<R>>(
        () => ({
            ...DATA_GRID_PROPS_DEFAULT_VALUES,
            ...themedProps,
            localeText,
            slots,
            slotProps: themedProps.slotProps ?? componentsProps,
            ...DATA_GRID_FORCED_PROPS,
        }),
        [themedProps, localeText, slots, componentsProps],
    );
};