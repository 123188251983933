import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProgressBarSliceType} from "./progressBar.interface";
import {RootState} from "../../app/store";

const initialState = {} as ProgressBarSliceType

const slice = createSlice({
    name: 'progress',
    initialState,
    reducers: {
        setProgress: (state, action: PayloadAction<{category: string, id: string | number, progress: number}>) => {
            return {
                ...state,
                [action.payload.category]: {
                    ...state[action.payload.category],
                    [action.payload.id]: action.payload.progress
                }
            }
        },
        deleteProgress: (state, action: PayloadAction<{ category: string; id: string | number }>) => {
            const { category, id } = action.payload;

            if (!state[category]) {
                return state;
            }

            const updatedCategory = { ...state[category] };
            delete updatedCategory[id];

            if (Object.keys(updatedCategory).length === 0) {
                const { [category]: deletedCategory, ...updatedState } = state;
                return updatedState;
            }

            return {
                ...state,
                [category]: updatedCategory,
            };
        },
    }
})

export const { setProgress, deleteProgress } = slice.actions
export default slice.reducer

export const getProgress = (state: RootState, category: string, id: string | number): number | undefined => state.progress?.[category]?.[id]
export const isCategoryInProgress = (state: RootState, category: string): boolean => !!state.progress?.[category]