import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './app/App';
import './app/i18n';
import theme from "./app/theme";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Provider} from "react-redux";
import {store, storePersistor} from "./app/store";
import {PersistGate} from "redux-persist/integration/react";
import AppLoader from "./components/appLoader/AppLoader";
import {GlobalStyles} from "@mui/material";

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Suspense fallback={<AppLoader/>}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={storePersistor}>
                    <BrowserRouter>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <GlobalStyles styles={{
                                html: {
                                    fontSize: '10px'
                                }
                            }}/>
                            <App/>
                        </ThemeProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </Suspense>
    </React.StrictMode>
);