import React from "react";
import {Popover} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {TipsAndUpdates} from "@mui/icons-material";
import {TipButtonParams} from "./tipButton.interface";
import {Trans} from "react-i18next";

const TipButton = ({
    hint
}: TipButtonParams) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <TipsAndUpdates color={"info"}/>
                <Typography variant="caption" display="block" align="center">
                    <Trans i18nKey={"common.hint"} />
                </Typography>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2, maxWidth: '300px' }}>{hint}</Typography>
            </Popover>
        </div>
    );
}

export default React.memo(TipButton)