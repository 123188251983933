import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import SentimentVerySatisfied from "@mui/icons-material/SentimentVerySatisfied";

const Logo = ({firstColor, secondColor, fontSize = '1.2em'}: {firstColor: string, secondColor: string, fontSize?: string}) => {

    return (
        <Stack
            direction="column"
            sx={{
                textTransform: 'none',
                alignItems: 'center',
                fontSize: fontSize,

                '& .MuiTypography-root': {
                    fontSize: '1em',
                }
            }}
        >
            <Stack direction="row">
                <SentimentVerySatisfied sx={{fontSize: '1.3em'}} htmlColor={firstColor}/>
                <Typography color={secondColor}>rganize</Typography>
            </Stack>
            <Typography color={secondColor}>
                <Typography color={firstColor} component="span">For</Typography> Me
            </Typography>
        </Stack>
    );
};

const MemoizedLogo = React.memo(Logo);

export default MemoizedLogo