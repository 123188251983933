import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {GridColDef} from "@mui/x-data-grid";
import {useAppDispatch} from "../../../app/store";
import {useLazyProjectTasksListQuery} from "../../../features/task/taskApi";
import GridMultivalueCell from "../../../components/gridMultivalueCell/GridMultivalueCell";
import {setComponentIsLoaded} from "../../../components/waitForLoad/waitForLoadSlice";
import AdvancedDataGrid from "../../../components/dataGrid/DataGrid";
import {ListRequest} from "../../../api/apiRequest.interface";
import {ListResponse} from "../../../api/apiResponse.interface";
import {ProjectTasksListGridParams, Task} from "../../../features/task/task.interface";
import AddNewTaskButton from "../../../features/task/AddNewTaskButton";
import {useDataGridUtils} from "../../../components/dataGrid/dataGridUtils";
import FeatureCard from "../../../components/featureCard/FeatureCard";
import Box from "@mui/material/Box";

const ProjectTasksListGrid = ({
    projectHash,
}: ProjectTasksListGridParams) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const datagridUtils = useDataGridUtils();
    const addNewRef = React.createRef<HTMLButtonElement>()
    const [loadList] = useLazyProjectTasksListQuery()

    const gridColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'tasksList.table.name',
            flex: 2,
            type: "string",
            minWidth: 250,
        },
        {
            field: 'contractors',
            headerName: 'tasksList.table.contractors',
            flex: 2,
            type: "string",
            sortable: false,
            minWidth: 250,
            renderCell: (params) =>
                <GridMultivalueCell
                    values={params.value}
                    formatValue={(value) => value.projectMember.name}
                />
        },
        {
            field: 'cost',
            headerName: 'tasksList.table.cost',
            flex: 1,
            type: "string",
            minWidth: 150,
        },
        {
            field: 'startDate',
            headerName: 'tasksList.table.startDate',
            flex: 1,
            type: "dateTime",
            minWidth: 150,
            valueFormatter: datagridUtils.formatDateTime,
        },
        {
            field: 'endDate',
            headerName: 'tasksList.table.endDate',
            flex: 1,
            type: "dateTime",
            minWidth: 150,
            valueFormatter: datagridUtils.formatDateTime,
        },
        {
            field: 'status',
            headerName: 'tasksList.table.status',
            flex: 1,
            type: "string",
            minWidth: 150,
            valueFormatter: (formatterParams) => formatterParams.value ? t(formatterParams.value.label) : ''
        },
    ];

    useEffect(() => {
        dispatch(setComponentIsLoaded({
            componentName: ProjectTasksListGrid.name,
            state: false
        }))
    }, [])

    const getGridRow = (task: Task, index: number) => {
        return {
            id: index,
            name: task.name,
            contractors: task.assignedMembers,
            cost: task.cost + ' ' + t(task.currency),
            startDate: task.startDate ? new Date(task.startDate * 1000) : null,
            endDate: task.endDate ? new Date(task.endDate * 1000) : null,
            status: task.status,
        }
    }

    return (
        <FeatureCard sx={{
            '& .FeatureCardBody': {
                py: 0
            },
            '& .FeatureCardHeader': {
                py: 2
            },
        }} header={
            <Box flexGrow={1} textAlign={'end'}>
                <AddNewTaskButton buttonRef={addNewRef} projectHash={projectHash} />
            </Box>
        }>
            <AdvancedDataGrid<Task>
                onComponentIsLoaded={() => {
                    dispatch(setComponentIsLoaded({
                        componentName: ProjectTasksListGrid.name,
                        state: true
                    }))
                }}
                gridColumns={gridColumns}
                getGridRow={getGridRow}
                loadList={async (listRequest: ListRequest): Promise<ListResponse> => {
                    return await loadList({
                        projectHash: projectHash,
                        listRequestData: listRequest
                    }, true).unwrap()
                }}
                onRowClick={(rowData) => navigate(`/project/${projectHash}/task/${rowData['number'] as string}`)}
                noRowsOverlayProps={{
                    onClickHandler: () => addNewRef.current?.click(),
                    noResultsText: t('tasksList.noTasks'),
                    buttonText: t('tasksList.createFirst')
                }}
                defaultSort={[
                    {field: 'startDate', sort: 'asc'}
                ]}
            />
        </FeatureCard>
    );
}

export default React.memo(ProjectTasksListGrid)