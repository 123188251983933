import {GridValueFormatterParams} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {useUtils} from "@mui/x-date-pickers/internals/hooks/useUtils";


export function useDataGridUtils() {
    const utils = useUtils();
    const {t} = useTranslation();

    const formatDateTime = (formatterParams: GridValueFormatterParams<any>): string => {
        return formatterParams?.value
            ? utils.format(formatterParams?.value, 'keyboardDateTime')
            : t('common.notSet')
    }

    return {formatDateTime}
}