import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useCreateProjectMutation, useProjectMetadataQuery} from "./projectApi";
import {useTypedSelector} from "../../app/store";
import {selectProjectFormMeta} from "./projectMetaSlice";
import CrudDialog from "../../components/crudDialog/CrudDialog";
import {TextField, Select } from 'mui-rff';
import {processSubmitErrors} from "../../components/form/formUtils";
import {CreateProjectDialogParams} from "./project.interface";

export default function CreateProjectDialog({
    open,
    setOpen
}: CreateProjectDialogParams) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {isLoading, isError} = useProjectMetadataQuery()
    const formMeta = useTypedSelector((state) => selectProjectFormMeta(state, 'project_create'))
    const [createProject] = useCreateProjectMutation()

    if (isLoading) {
        return null;
    }

    if (!formMeta || isError) {
        throw new Error('Can\'t load project_create form meta')
    }

    const onSubmit = async (values: {name?: string, type?: string}) => {
        let errors = {}

        try {
            const project = await createProject(values).unwrap()
            navigate(`/project/${project.hash}`);
        } catch (e) {
            errors = processSubmitErrors(e, formMeta)
        }

        return errors
    }

    return (
        <CrudDialog
            open={open}
            setOpen={setOpen}
            title={'createProject.newProject'}
            submitButtonLabel={'common.create'}
            onSubmit={onSubmit}
            formMeta={formMeta}
            content={() => (
                <React.Fragment>
                    <TextField
                        name={'name'}
                        variant={"standard"}
                        label={t('project.name')}
                        fullWidth
                    />
                    <Select
                        name={'type'}
                        variant={"standard"}
                        label={t('project.type') || ''}
                        fullWidth
                        data={(formMeta.fields?.['type']?.choices) || []}
                    />
                </React.Fragment>
            )}
        />
    );
}