import {GridFilterAltIcon} from "@mui/x-data-grid";
import React, {useState} from "react";
import {DataGridFiltersButtonProps} from "./dataGrid.interface";
import theme from "../../app/theme";
import {Trans} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const DataGridFiltersButton = ({gridApiRef}: DataGridFiltersButtonProps) => {
    // @ts-ignore
    const [filtersState, setFiltersState] = useState<boolean>(!!gridApiRef.current.state?.showHeaderFilters)

    return (
        <IconButton onClick={() => {
                gridApiRef.current.setState(previousState => ({
                    ...previousState,
                    showHeaderFilters: !filtersState
                }));
                gridApiRef.current.forceUpdate();
                setFiltersState(!filtersState)
            }}
            sx={{
                color: filtersState ? theme.palette.primary.main : "initial"
            }}
        >
            <GridFilterAltIcon/>
            <Typography variant="caption" display="block" align="center">
                <Trans i18nKey={"grid.filters"} />
            </Typography>
        </IconButton>
    )
}

export default React.memo(DataGridFiltersButton)