import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import {DownloadButtonProps} from "./downloadButton.interface";
import {displayErrors} from "../../service/errorService";
import {useDownloadFile} from "../../hooks/useDonwloadFile";
import CircularProgressWithLabel from "../circularProgressWithLabel/CircularProgressWithLabel";

export const DownloadButton: React.FC<DownloadButtonProps> = ({
    fileName,
    apiDefinition,
}) => {
    const [downloadProgress, setDownloadProgress] = useState<number | undefined>()
    const preDownloading = () => setDownloadProgress(0)
    const postDownloading = () => setDownloadProgress(undefined)
    const onError = () => {
        setDownloadProgress(undefined)
        displayErrors('error.cantDownloadFile')
    }
    const { download } = useDownloadFile({
        apiDefinition: async () => await apiDefinition((progress) => setDownloadProgress(progress)),
        preDownloading,
        postDownloading,
        onError,
        fileName,
    });

    return (
            <IconButton aria-label="delete" onClick={download} disabled={downloadProgress !== undefined}>
                {downloadProgress !== undefined && (
                    <CircularProgressWithLabel value={downloadProgress}/>
                )}
                {downloadProgress === undefined && (
                    <DownloadIcon color={"action"} />
                )}
            </IconButton>
    );
};

export default React.memo(DownloadButton)