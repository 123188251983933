import React, {useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import RequireAuth from '../features/auth/RequireAuth';
import NotFoundRoute from '../components/error/NotFoundRoute';
import ErrorRenderer from '../components/error/ErrorRenderer';
import ProjectsListPage from "../pages/projectList/ProjectsListPage";
import LoginPage from "../pages/login/LoginPage";
import ProjectPage from "../pages/project/ProjectPage";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/uk';
import {ConfirmProvider} from "material-ui-confirm";
import TaskPage from "../pages/task/TaskPage";
import {SnackbarProvider} from "notistack";
import theme from "./theme";
import {withTranslation} from "react-i18next";
import ErrorBoundary from "../components/error/ErrorBoundary";
import MainApp from "../layout/main/MainApp";

const locales = ['en', 'uk'];
type LocaleKey = (typeof locales)[number];

const App = withTranslation()(() => {
    const [locale, ] = useState<LocaleKey>('uk');

    return (
        <React.Fragment>
            <ErrorBoundary>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <SnackbarProvider autoHideDuration={5000} style={{fontSize: theme.typography.fontSize}}/>
                    <ConfirmProvider>
                        <Routes>
                            <Route path="/" element={
                                <RequireAuth>
                                    <MainApp/>
                                </RequireAuth>
                            }>
                                <Route index element={<ProjectsListPage/>}/>
                                <Route path="/project/:id" element={<ProjectPage/>}/>
                                <Route path="/project/:projectHash/task/:id" element={<TaskPage/>}/>
                                <Route path='*' element={<ErrorRenderer><NotFoundRoute/></ErrorRenderer>}/>
                            </Route>
                            <Route path="/login" element={<LoginPage/>}/>
                            {/*<Route path='/reset-password' element={<ErrorBoundary page="reset-password"><PageAuthWrapper /></ErrorBoundary>} >*/}
                            {/*    <Route path=':tokenl/:tokenp/:email/*' element={<ResetPasswordPage />} />*/}
                            {/*</Route>*/}
                        </Routes>
                        {/*<NoInternetConnectionComponent />*/}
                    </ConfirmProvider>
                </LocalizationProvider>
            </ErrorBoundary>
        </React.Fragment>
    );
});

export default App;
