import React from "react";
import {Card, Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {FeatureCardProps} from "./featureCard.interface";

const FeatureCard = ({ header, children, maxHeight = false, sx }: FeatureCardProps) => {

    return (
        <Card sx={{
            px: 4,
            ...(maxHeight ? {height: '100%'} : null),
            '& .FeatureCardBody': {
                pt: 2,
                pb: 5
            },
            '& .FeatureCardHeader': {
                px: 4,
                py: 1
            },
            ...sx,
        }}>
            {header ? <React.Fragment><Box className={'FeatureCardHeader'}>
                {header}
            </Box><Divider /></React.Fragment> : null}
            <Box className={'FeatureCardBody'}>{children}</Box>
        </Card>
    )
}

export default React.memo(FeatureCard)