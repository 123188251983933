interface DownloadFileProps {
    readonly apiDefinition: () => Promise<Blob>;
    readonly preDownloading: () => void;
    readonly postDownloading: () => void;
    readonly onError: () => void;
    readonly fileName: string;
}

interface DownloadedFileInfo {
    readonly download: () => Promise<void>;
}

export const useDownloadFile = ({
        apiDefinition,
        preDownloading,
        postDownloading,
        onError,
        fileName,
    }: DownloadFileProps): DownloadedFileInfo => {

    const download = async () => {
        try {
            preDownloading();
            const data = await apiDefinition();
            const href = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            postDownloading();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            onError();
        }
    };

    return { download };
};