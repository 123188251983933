import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {api, rtkQueryErrorProcessor} from '../api/api'
import auth from '../features/auth/authSlice'
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist'
import projectMeta from "../features/project/projectMetaSlice";
import taskMeta from "../features/task/taskMetaSlice";
import tasks from "../features/task/taskSlice";
import projects from "../features/project/projectSlice";
import projectMembersMeta from "../features/project/projectMembersMetaSlice";
import waitForLoad from "../components/waitForLoad/waitForLoadSlice";
import breadcrumbs from "../components/breadcrumbs/breadcrumbsSlice";
import progress from "../components/progressBar/progressBarSlice";

const persistConfig = {
    key: 'root',
    storage,
}

const authReducer = persistReducer(persistConfig, auth)

export const createStore = (
    options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
    configureStore({
        devTools: process.env.NODE_ENV !== 'production',
        reducer: {
            [api.reducerPath]: api.reducer,
            auth: authReducer,
            projectMeta,
            projects,
            waitForLoad,
            projectMembersMeta,
            taskMeta,
            tasks,
            progress,
            breadcrumbs
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(api.middleware, rtkQueryErrorProcessor),
        ...options,
    })

export const store = createStore()
export const storePersistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector