import React from 'react';
import { useTypedSelector } from '../../app/store';
import {selectIsComponentLoaded} from "./waitForLoadSlice";
import AppLoader from "../appLoader/AppLoader";
import {WaitForLoadProps} from "./waitForLoad.interface";

const MemoizedChildren = React.memo((props: {children: React.JSX.Element | React.JSX.Element[]}) => {
    return <div>{props.children}</div>
})

const WaitForLoad =({components, children}: WaitForLoadProps) => {
    const isComponentLoaded = (componentName: string) =>  useTypedSelector((state) => selectIsComponentLoaded(state, componentName))

    const allComponentsAreLoaded = () => {
        for (const componentName of components) {
            if (!isComponentLoaded(componentName)) {
                return false;
            }
        }

        return true;
    }

    return (
        <React.Fragment>
            <div style={{width: '100%'}} hidden={allComponentsAreLoaded()}><AppLoader /></div>
            <div style={{width: '100%'}} hidden={!allComponentsAreLoaded()}><MemoizedChildren>{children}</MemoizedChildren></div>
        </React.Fragment>
    );
}

export default React.memo(WaitForLoad)