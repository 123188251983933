import { Theme } from '@mui/material/styles';
import theme from "../../app/theme";

const styles = (theme: Theme) => {
    return {
        width: '100%',

        '& .MuiInput-previewed': {
            '& .MuiFormHelperText-root p': {
                margin: 0
            },
            '&.Mui-LabelLess, &.Mui-TitleField': {
                '& .MuiInputBase-multiline': {
                    paddingTop: '2px',
                },
                '& .MuiInputBase-input': {
                    paddingTop: '5px'
                },
                '& .MuiInputLabel-root': {
                    display: 'none'
                },
            },
            '&.Mui-TitleField': {
                '& .MuiInputBase-input': {
                    fontWeight: '600',
                    fontSize: '1em',
                    [theme.breakpoints.up('md')]: {
                        fontSize: '1.5em',
                    },
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                },
            },
            '& .MuiInputBase-root': {
                '&::before': {
                    border: 'none',
                },
                '&::after': {
                    borderBottom: '1px solid ' + theme.palette.secondary.dark,
                },
                '&:not(:hover, .Mui-focused)': {
                    backgroundColor: 'inherit',

                    '& .MuiInputAdornment-root:not(.alwaysShow)': {
                        display: 'none'
                    },
                    '& .MuiSvgIcon-root': {
                        display: 'none'
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield'
                    },
                },
                '&:not(.Mui-focused, .Mui-disabled) .MuiInputBase-input:hover': {
                    cursor: 'pointer'
                },
                '&:hover:not(.Mui-disabled, .Mui-focused)': {
                    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
                    borderRadius: '4px',
                    backgroundColor: 'white',

                    '&:before': {
                        transition: 'none',
                        border: 'none',
                    },
                },
                '&.Mui-focused': {
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2), inset 0px -2px 1px ' + theme.palette.primary.dark,
                    [theme.breakpoints.up('md')]: {
                        boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2), inset 0px -4px 1px ' + theme.palette.primary.dark,
                    },
                    borderRadius: '4px',

                    '&::after, &:hover:after, &::before, &:hover:before': {
                        transition: 'none',
                        border: 'none',
                    },
                    "& .MuiInputBase-input::placeholder": {
                        opacity: '0!important',
                    },
                },
                '&.Mui-error .MuiInputBase-input::placeholder': {
                    color: 'rgba(255, 0, 0, 0.7)',
                },
                '& .MuiInputBase-input::placeholder': {
                    color: 'rgba(0, 0, 0, 0.3)',
                    opacity: '1!important',
                },
                '& .MuiInputAdornment-root': {
                    marginTop: '18px'
                }
            },
            "& .MuiInput-input.Mui-disabled": {
                color: theme.palette.common.black,
                WebkitTextFillColor: theme.palette.common.black
            },
            "& .MuiInputLabel-root": {
                top: '-5px',
                cursor: 'pointer',
                transform: 'translate(12px, 7px) scale(0.75)',
            }
        }
    };
}

export const formStyles = styles(theme)