import {FormsMetaResponse, GetResponse, ListResponse} from "../../api/apiResponse.interface";
import {api} from "../../api/api";
import {ListRequest} from "../../api/apiRequest.interface";
import {Task} from "./task.interface";
type ProjectTasksListQueryParams = {
    projectHash: string,
    listRequestData: ListRequest
}

export const taskApi = api.injectEndpoints({
    endpoints: (builder) => ({
        projectTasksList: builder.query<ListResponse, ProjectTasksListQueryParams>({
            query: (queryData: ProjectTasksListQueryParams) => ({
                url: `project/${queryData.projectHash}/task/list`,
                method: 'POST',
                body: {
                    tasks: queryData.listRequestData
                },
            }),
            keepUnusedDataFor: 2,
            transformResponse: (response: {status: number, data: ListResponse}) => {
                return response.data
            }
        }),
        taskMetadata: builder.query<FormsMetaResponse, {projectHash: string}>({
            providesTags: ['Metadata'],
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/metadata`,
                method: 'GET',
            }),
            transformResponse: (response: {status: number, data: {forms: FormsMetaResponse}}) => {
                return response.data.forms
            }
        }),
        getTask: builder.query<Task, {projectHash: string, taskId: number}>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/${queryData.taskId}/get`,
                method: 'GET',
            }),
            transformResponse: (response: {status: number, data: GetResponse}) => {
                return response.data.entity.task as Task
            }
        }),
        createTask: builder.mutation<Task, {projectHash: string, data: {name?: string, type?: string}}>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/create`,
                method: 'POST',
                body: queryData.data,
            }),
            transformResponse: (response: {status: number, data: GetResponse}) => {
                return response.data.entity.task as Task
            }
        }),
        updateTask: builder.mutation<Task, {projectHash: string, taskId: number, data: {[key: string]: any }}>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/${queryData.taskId}/edit`,
                method: 'PUT',
                body: queryData.data,
            }),
            transformResponse: (response: {status: number, data: GetResponse}) => {
                return response.data.entity.task as Task
            }
        }),
    }),
})

export const {
    useLazyProjectTasksListQuery,
    useTaskMetadataQuery,
    useGetTaskQuery,
    useCreateTaskMutation,
    useUpdateTaskMutation,
} = taskApi

export const {
    endpoints: { projectTasksList, taskMetadata, getTask, createTask, updateTask },
} = taskApi