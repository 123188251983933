import * as React from 'react';
import {Container, useMediaQuery} from "@mui/material";
import SideBar, {MenuItem} from "../sideBar/SideBar";
import {useTranslation} from "react-i18next";
import {Outlet} from "react-router-dom";
import Box from "@mui/material/Box";
import {AccountTree} from "@mui/icons-material";
import theme from "../../app/theme";

export default function MainApp() {
    const {t} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const menuItems: MenuItem[] = [
        {
            label: t('pages.projects'),
            url: '/',
            icon: <AccountTree />,
        }
    ]

    return (
        <Container maxWidth={'lg'} sx={{
            mt: isMobile ? '1vh' : '3vh',
            px: 2,
            [theme.breakpoints.down('md')]: {
                pr: 0,
                pl: 1
            },
            display: 'flex'
        }}>
            <SideBar menuItems={menuItems} />
            <Box sx={{flexGrow: 1, width: '100%', height: 'auto', overflowX: 'hidden'}}>
                <Outlet/>
            </Box>
        </Container>
    );
}