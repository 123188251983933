import React from "react";
import {GridAddIcon, NoRowsOverlayPropsOverrides} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

declare module "@mui/x-data-grid" {
    interface NoRowsOverlayPropsOverrides {
        onClickHandler?: () => void;
        noResultsText?: string | null;
        buttonText?: string | null;
    }
}

const NoRowsOverlay = ({
    onClickHandler,
    noResultsText,
    buttonText
}: NoRowsOverlayPropsOverrides) => {

    return (
        <Stack className={'MuiDataGrid-noRowsOverlay'}>
            <Typography mb={3} variant={"h5"}>{noResultsText}</Typography>
            <Button variant="contained" onClick={onClickHandler} startIcon={<GridAddIcon />}>{buttonText}</Button>
        </Stack>
    );
}

export default NoRowsOverlay;