import Form from "../../../components/form/Form";
import React, {useEffect, useRef} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "mui-rff";
import {AnyObject} from "react-final-form";
import {FormMeta, FormState} from "../../../components/form/form.interface";
import {useTranslation} from "react-i18next";
import {Signal} from "@preact/signals-react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import FormStateControl from "../../../components/form/FormStateControl";
import {Task} from "../../../features/task/task.interface";
import Select from "../../../components/fields/select/Select";
import {FormApi} from "final-form";

type TaskDetailsFormProps = {
    onSubmit: (values: AnyObject, form: FormApi) => Promise<{}>,
    formMeta: FormMeta,
    data: Task,
    formsState: Signal<FormState[]>
}

const TaskDetailsForm = ({
    onSubmit,
    formMeta,
    data,
    formsState
}: TaskDetailsFormProps) => {
    const {t} = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);

    // Textarea has a bug and can't be resized it's a workaround till, maybe mui will fix this in the future....
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    return (
        <Form
            onSubmit={onSubmit}
            formMeta={formMeta}
            initialValues={data}
            subscription={{}}
            render={({handleSubmit,}) => (
                <form style={{width: '100%', flexGrow: 1}} ref={formRef} onSubmit={handleSubmit}>
                    <FormStateControl name={'ProjectDetails'} formRef={formRef} formsState={formsState} />
                    <List sx={{flex: 1}}>
                        <ListItem>
                            <TextField
                                name={'description'}
                                variant={"filled"}
                                label={t('task.description')}
                                className={'MuiInput-previewed'}
                                placeholder={t('common.fieldPlaceholder') || ''}
                                fullWidth
                                multiline
                            />
                        </ListItem>
                        <ListItem>
                            <Select
                                formControlProps={{
                                    className: 'MuiInput-previewed'
                                }}
                                name={'paymentStatus'}
                                variant={"filled"}
                                label={t('task.paymentStatus') || ''}
                                fullWidth
                                data={(formMeta.fields?.['paymentStatus']?.choices) || []}
                            />
                        </ListItem>
                        <ListItem>
                            <Grid container spacing={5} flexGrow={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'cost'}
                                        variant={"filled"}
                                        type={"number"}
                                        label={t('task.cost')}
                                        className={'MuiInput-previewed'}
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            },
                                            startAdornment:
                                                <InputAdornment position={"end"} className={'alwaysShow'} sx={{mr: 1, ml: 0}}>
                                                    <Typography>{t(String(data.currency || ''))}</Typography>
                                                </InputAdornment>
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name={'prepayment'}
                                        variant={"filled"}
                                        type={"number"}
                                        label={t('task.prepayment')}
                                        className={'MuiInput-previewed'}
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            },
                                            startAdornment:
                                                <InputAdornment position={"end"} className={'alwaysShow'} sx={{mr: 1, ml: 0}}>
                                                    <Typography>{t(String(data.currency || ''))}</Typography>
                                                </InputAdornment>
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                </form>
            )}
        />
    )
}

export default React.memo(TaskDetailsForm)