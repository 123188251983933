import React from "react";
import {styled} from "@mui/material/styles";
import {formStyles} from "./formStyles";
import {Form as BaseForm, FormProps} from "react-final-form";
import {FormMeta, LabeledValue} from "./form.interface";
import {generateValidationSchema} from "./formUtils";
import {makeValidate} from "mui-rff";
import {useTranslation} from "react-i18next";

const StyledForm = styled('div')(formStyles);

function isLabeledValue(obj: any): obj is LabeledValue {
    return obj && obj?.value;
}

const Form:React.FC<FormProps & {formMeta?: FormMeta}> = ({ initialValues, validate, formMeta, ...rest }) =>  {
    const {t} = useTranslation();

    if (formMeta && initialValues !== undefined) {
        initialValues = Object.keys(initialValues)
            .filter((fieldName) => !!formMeta?.fields?.[fieldName])
            .reduce((obj: Record<string, any>, fieldName: string) => {
                // @ts-ignore
                obj[fieldName] = initialValues[fieldName];
                return obj;
            }, {});
    }

    if (!validate && formMeta) {
        // @ts-ignore
        validate = makeValidate(generateValidationSchema(formMeta), (innerError) => t(innerError.message));
    }

    if (initialValues) {
        Object.keys(initialValues).forEach(key => {
            // @ts-ignore
            if (isLabeledValue(initialValues[key])) {
                // @ts-ignore
                initialValues[key] = initialValues[key].value
            }
        })
    }

    return (
        <StyledForm>
            <BaseForm initialValues={initialValues} validate={validate} {...rest} />
        </StyledForm>
    )
}

export default Form;