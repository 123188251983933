import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

const NotFoundRoute = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/', { replace: true });
    }, []);

    return (
        <div>404 NOT FOUND</div>
    );
};

export default NotFoundRoute;
