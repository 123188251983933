import React from "react";
import {Navigate} from "react-router-dom";
import theme from "../../app/theme";
import MemoizedLogo from "../../layout/logo/Logo";
import LoginForm from "./parts/LoginForm";
import {selectIsAuthenticated} from "../../features/auth/authSlice";
import {useSelector} from "react-redux";
import Container from "@mui/system/Container";
import {Card} from "@mui/material";

const LoginPage = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated)

    if (isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return (
        <Container
            maxWidth={"sm"}
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
            }}
        >
            <Card sx={{width: '100%', p: 5}}>
                <div style={{width: "100%", marginBottom: 30}}>
                    <MemoizedLogo fontSize={'3em'} firstColor={theme.palette.primary.main} secondColor={theme.palette.secondary.dark} />
                </div>
                <div style={{width: "100%"}}>
                    <LoginForm />
                </div>
            </Card>
        </Container>
    );
}

export default LoginPage;
