import * as React from 'react';
import PropTypes from 'prop-types';
import {
    refType,
    unstable_useEventCallback as useEventCallback,
} from '@mui/utils';
import {
    useGridApiEventHandler,
    useGridApiContext,
} from '@mui/x-data-grid';
import {
    GridBaseColumnHeaders,
    GridColumnHeadersInner,
    UseGridColumnHeadersProps,
} from '@mui/x-data-grid/internals';
import {useGridColumnHeaders} from "./useGridColumnHeaders";

interface DataGridColumnHeadersProps
    extends React.HTMLAttributes<HTMLDivElement>,
        Omit<UseGridColumnHeadersProps, 'innerRef'> {
    innerRef?: React.Ref<HTMLDivElement>;
}

const DataGridColumnHeaders = React.forwardRef<HTMLDivElement, DataGridColumnHeadersProps>(
    function GridColumnHeaders(props, ref) {
        const {
            style,
            className,
            innerRef,
            visibleColumns,
            sortColumnLookup,
            filterColumnLookup,
            columnPositions,
            columnHeaderTabIndexState,
            columnGroupHeaderTabIndexState,
            columnHeaderFocus,
            columnGroupHeaderFocus,
            densityFactor,
            headerGroupingMaxDepth,
            columnMenuState,
            columnVisibility,
            columnGroupsHeaderStructure,
            hasOtherElementInTabSequence,
            ...other
        } = props;
        const apiRef = useGridApiContext();
        const [scrollbarSize, setScrollbarSize] = React.useState(0);

        const handleContentSizeChange = useEventCallback(() => {
            const rootDimensions = apiRef.current.getRootDimensions();
            if (!rootDimensions) {
                return;
            }

            const newScrollbarSize = rootDimensions.hasScrollY ? rootDimensions.scrollBarSize : 0;
            if (scrollbarSize !== newScrollbarSize) {
                setScrollbarSize(newScrollbarSize);
            }
        });

        useGridApiEventHandler(apiRef, 'virtualScrollerContentSizeChange', handleContentSizeChange);

        const {
            isDragging,
            renderContext,
            getRootProps,
            getInnerProps,
            getColumnHeaders,
            getColumnFilters,
        } = useGridColumnHeaders({
            innerRef,
            visibleColumns,
            sortColumnLookup,
            filterColumnLookup,
            columnPositions,
            columnHeaderTabIndexState,
            hasOtherElementInTabSequence,
            columnGroupHeaderTabIndexState,
            columnHeaderFocus,
            columnGroupHeaderFocus,
            densityFactor,
            headerGroupingMaxDepth,
            columnMenuState,
            columnVisibility,
            columnGroupsHeaderStructure,
        });

        const innerProps = getInnerProps();

        return (
            <GridBaseColumnHeaders ref={ref} className={className} {...getRootProps(other)}>
                <GridColumnHeadersInner isDragging={isDragging} {...innerProps}>
                    {getColumnHeaders({
                        renderContext,
                    })}
                    {getColumnFilters({
                        renderContext,
                    })}
                </GridColumnHeadersInner>
            </GridBaseColumnHeaders>
        );
    },
);

DataGridColumnHeaders.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    columnGroupHeaderFocus: PropTypes.shape({
        depth: PropTypes.number.isRequired,
        field: PropTypes.string.isRequired,
    }),
    columnGroupHeaderTabIndexState: PropTypes.shape({
        depth: PropTypes.number.isRequired,
        field: PropTypes.string.isRequired,
    }),
    columnGroupsHeaderStructure: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                columnFields: PropTypes.arrayOf(PropTypes.string).isRequired,
                groupId: PropTypes.string,
            }),
        ),
    ).isRequired,
    columnHeaderFocus: PropTypes.shape({
        field: PropTypes.string.isRequired,
    }),
    columnHeaderTabIndexState: PropTypes.shape({
        field: PropTypes.string.isRequired,
    }),
    columnMenuState: PropTypes.shape({
        field: PropTypes.string,
        open: PropTypes.bool.isRequired,
    }).isRequired,
    columnPositions: PropTypes.arrayOf(PropTypes.number).isRequired,
    columnVisibility: PropTypes.object.isRequired,
    densityFactor: PropTypes.number.isRequired,
    filterColumnLookup: PropTypes.object.isRequired,
    hasOtherElementInTabSequence: PropTypes.bool.isRequired,
    headerGroupingMaxDepth: PropTypes.number.isRequired,
    innerRef: refType,
    minColumnIndex: PropTypes.number,
    sortColumnLookup: PropTypes.object.isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
} as any;

export { DataGridColumnHeaders };