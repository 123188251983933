import React from "react";

const GridMultivalueCell = ({
    values,
    formatValue
}: {values: any, formatValue?: (value: any) => string}) => {

    if (!Array.isArray(values)) {
        return null;
    }

    function truncate(source: string, size: number) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    return (<React.Fragment>
        {values.length > 0 ? truncate(String(formatValue ? formatValue(values[0]) : values[0]), 20) : ''}
        {values.length > 1 ? <span style={{
            position: 'relative',
            left: 10,
            backgroundColor: '#E88A03',
            borderRadius: 20,
            color: 'white',
            padding: '2px 4px'
        }}>+{values.length - 1}</span> : ''}
    </React.Fragment>)
};

export default React.memo(GridMultivalueCell)