import createTheme from "@mui/material/styles/createTheme";
import {responsiveFontSizes} from "@mui/material";
// @ts-ignore
import AngstVF from './fonts/AngstVF.ttf';

declare module '@mui/material/styles' {
    interface CustomTheme {
        sideBarWidth?: number
    }

    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}

// A custom theme for this app
const defaultTheme = createTheme({
    shape: {
        borderRadius: 12,
    },
    palette: {
        primary: {
            main: '#E88A03',
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: '#b5c2ca',
            light: '#f0f3f4',
            dark: '#053b4f',
            contrastText: "#000000"
        },
        background: {
            default: '#b5c2ca'
        }
    },
    typography: {
        fontFamily: 'AngstVF, Arial',
        htmlFontSize: 10,
        h1: {
            fontSize: "7.5rem",
        },
        h2: {
            fontSize: "6.8rem",
        },
        h3: {
            fontSize: "5.3rem",
        },
        h4: {
            fontSize: "4.5rem",
        },
        h5: {
            fontSize: "3.6rem",
        },
        h6: {
            fontSize: "2.6rem",
        },
        caption: {
            fontSize: "1.8rem",
        },
        body1: {
            fontSize: "1.8rem",
        },
        body2: {
            fontSize: "1.6rem",
        },
        button: {
            fontSize: "1.4rem",
            fontWeight: "bold"
        }
    },
    sideBarWidth: 240
});

const theme = responsiveFontSizes(createTheme({
    ...defaultTheme,
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'AngstVF';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('AngstVF'), local('AngstVF'), url(${AngstVF}) format('ttf');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
              `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    [defaultTheme.breakpoints.down("sm")]: {
                        padding: '4px 10px',
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthXl: {
                    [defaultTheme.breakpoints.up("md")]: {
                        maxWidth: 1900
                    },
                },
                maxWidthLg: {
                    [defaultTheme.breakpoints.up("md")]: {
                        maxWidth: 1800
                    },
                },
                maxWidthMd: {
                    [defaultTheme.breakpoints.up("md")]: {
                        maxWidth: 1600
                    },
                }
            }
        },
    },
}), )

export default theme;