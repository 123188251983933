import Password from "../../../components/fields/password/Password";
import React, {useRef} from "react";
import {Form} from "react-final-form";
import {useTranslation} from "react-i18next";
import { TextField, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import {useLoginMutation} from "../../../features/auth/authApi";
import {displayErrors} from "../../../service/errorService";
import {FormApi} from "final-form";
import Grid from "@mui/material/Grid";
import {signal} from "@preact/signals-react";
import {FormState} from "../../../components/form/form.interface";
import FormStateControl from "../../../components/form/FormStateControl";
import FormSubmitButton from "../../../components/form/FormSubmitButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const LoginForm = () => {
    const { t } = useTranslation();
    const [login] = useLoginMutation()
    const formsState = signal<FormState[]>([])
    const formRef = useRef<HTMLFormElement>(null);

    const schema = Yup.object({
        phone: Yup.string().required('validation.notBlank'),
        password: Yup.string().required('validation.notBlank'),
    });

    // @ts-ignore
    const validate = makeValidate(schema, (innerError) => t(innerError.message));

    const onSubmit = async (values: {login?: string, password?: string}, form: FormApi) => {
        try {
            await login(values).unwrap()
        } catch (e) {
            displayErrors(e)
            form.mutators.resetPassword()
        }

        return {}
    }

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            rowSpacing={3}
            sx={{width: '100%'}}
        >
            <Grid item sx={{width: '100%'}}>
                <Form
                    onSubmit={onSubmit}
                    // @ts-ignore
                    validate={validate}
                    subscription={{}}
                    mutators={{
                        resetPassword: (_, state, utils) => {
                            utils.changeValue(state, 'password', () => '')
                        },
                    }}
                    render={({handleSubmit}) => (
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <FormStateControl formsState={formsState} formRef={formRef} name={'Login'}/>
                            <List>
                                <ListItem>
                                    <TextField
                                        name={'phone'}
                                        variant={"standard"}
                                        label={t('login.login')}
                                        required={false}
                                        fullWidth
                                    />
                                </ListItem>
                                <ListItem>
                                    <Password
                                        name={'password'}
                                        variant={"standard"}
                                        label={t('login.password')}
                                        required={false}
                                        fullWidth
                                    />
                                </ListItem>
                            </List>
                        </form>
                    )}
                />
            </Grid>
            <Grid item sx={{
                mt: 1,
                width: '100%'
            }}>
                <FormSubmitButton title={t('login.signIn')} sx={{width: '30%'}} formsState={formsState} />
            </Grid>
        </Grid>
    )
}

export default LoginForm