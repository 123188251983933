import ListBlock from "../../../components/listBlock/ListBlock";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import ProjectMemberRoleIcon from "../../../features/project/ProjectMemberRoleIcon";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {setComponentIsLoaded} from "../../../components/waitForLoad/waitForLoadSlice";
import {useAppDispatch, useTypedSelector} from "../../../app/store";
import {TaskMember, TaskMembersListBlockParams} from "../../../features/task/task.interface";
import {getTaskMembers} from "../../../features/task/taskSlice";
import CrudTaskMemberDialog from "../../../features/task/CrudTaskMemberDialog";

const TaskMembersListBlock = ({
    projectHash,
    taskId
}: TaskMembersListBlockParams) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [taskMemberDialogState, setTaskMemberDialogState] = useState<{open: boolean, taskMember?: TaskMember, mode: 'edit'|'create'}>({open: false, mode: "create"})
    const taskMembers = useTypedSelector((state) => getTaskMembers(state, projectHash, taskId))

    useEffect(() => {
        dispatch(setComponentIsLoaded({
            componentName: TaskMembersListBlock.name,
            state: taskMembers !== undefined
        }))

    }, [taskMembers]);

    if (taskMembers === undefined) {
        return null
    }

    function getListItemLine(item: TaskMember): React.ReactElement {
        return (
            <Grid container spacing={2}>
                <Grid item container justifyContent={"flex-start"} alignContent={"center"} xs={1}>
                    <ProjectMemberRoleIcon memberRoles={item.projectMember.roles} />
                </Grid>
                <Grid item container justifyContent={"flex-start"} alignContent={"center"} xs={5}>
                    <Typography variant={"caption"} sx={{wordBreak: "break-word", fontWeight: "bold"}}>{item.projectMember.name}</Typography>
                </Grid>
                <Grid item container justifyContent={"flex-end"} alignContent={"center"} xs={5}>
                    <Typography variant={"caption"} sx={{wordBreak: "break-word"}}>{item.projectMember.user.phone}</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <CrudTaskMemberDialog
                open={taskMemberDialogState.open}
                setOpen={(state) => setTaskMemberDialogState(old => ({...old, open: state}))}
                mode={taskMemberDialogState.mode}
                taskMember={taskMemberDialogState.taskMember}
                taskId={taskId}
                projectHash={projectHash}
            />
            <ListBlock
                getItemLine={getListItemLine}
                items={taskMembers}
                addButton={{
                    text: t('task.addContractor'),
                    onClick: () => setTaskMemberDialogState({
                        open: true,
                        taskMember: undefined,
                        mode: "create"
                    })
                }}
                editButtonOnClick={(itemIndex) => setTaskMemberDialogState({
                    open: true,
                    taskMember: taskMembers[itemIndex],
                    mode: "edit"
                })}
            />
        </React.Fragment>
    )
}

export default React.memo(TaskMembersListBlock)