import React from "react";
import dayjs, {Dayjs} from "dayjs";
import { DateTimePickerProps, DateTimePicker as BaseDateTimePicker } from "mui-rff";
dayjs.extend(require('dayjs/plugin/localizedFormat'))

const DateTimePicker:React.FC<DateTimePickerProps> = props =>  {

    return (
        <BaseDateTimePicker
            {...props}
            fieldProps={{
                format: (value: any) => value ? dayjs(String(value), 'X') : undefined,
                parse: (value: Dayjs | null, ) => value?.unix()
            }}
        />
    )
}

export default DateTimePicker;