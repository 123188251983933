import React from "react";
import {useTranslation} from "react-i18next";
import {ProjectMemberRoleIconOption, ProjectMemberRoleIconProps} from "./project.interface";
import theme from "../../app/theme";
import CrownLineIcon from "../../app/icons/CrownLineIcon";
import CrownStarIcon from "../../app/icons/CrownStarIcon";
import WorkerIcon from "../../app/icons/WorkerIcon";

const memberRolesIcons: ProjectMemberRoleIconOption[] = [
    {role: "1", title: 'projectMember.role.owner', icon: CrownStarIcon, color: theme.palette.primary.dark},
    {role: "2", title: 'projectMember.role.organizer', icon: CrownLineIcon, color: theme.palette.primary.main},
    {role: "3", title: 'projectMember.role.contractor', icon: WorkerIcon, color: theme.palette.secondary.dark},
]

const ProjectMemberRoleIcon = ({
        memberRoles
    }: ProjectMemberRoleIconProps) => {

    const {t} = useTranslation();
    let memberIcon = null;

    for (const roleOption of memberRolesIcons) {
        if (memberRoles.find(memberRole => memberRole.role.value === roleOption.role)) {
            memberIcon = <roleOption.icon fontSize={'2em'} fill={roleOption.color} title={t(roleOption.title)} />
            break;
        }
    }

    return (memberIcon)
};

export default React.memo(ProjectMemberRoleIcon)