import i18n from "i18next";
import {enqueueSnackbar} from "notistack";

export const displayErrors = (error: any): void => {

    console.log(error)
    const messages =  error.messages

    if (messages && Array.isArray(messages)) {
        messages.forEach((value: string) => enqueueSnackbar(i18n.t(value), {variant: "error", preventDuplicate: true}))
    } else {
        enqueueSnackbar(i18n.t('error.unknown'), {variant: "error", preventDuplicate: true})
    }
}