import React from "react";
import { SelectProps, Select as BaseSelect } from "mui-rff";

const Select:React.FC<SelectProps> = props =>  {

    return (
        <BaseSelect
            {...props}
            fieldProps={{
                format: (value: any) => value.value !== undefined ? value.value.toString() : value,
            }}
        />
    )
}

export default Select;