import Form from "../../../components/form/Form";
import React, {useRef} from "react";
import {AnyObject} from "react-final-form";
import {FormMeta, FormState} from "../../../components/form/form.interface";
import {Signal} from "@preact/signals-react";
import FormStateControl from "../../../components/form/FormStateControl";
import {Task} from "../../../features/task/task.interface";
import {FormApi} from "final-form";
import TaskAgendaField from "../../../features/task/TaskAgendaField";
import arrayMutators from "final-form-arrays";

type TaskHeaderFormProps = {
    onSubmit: (values: AnyObject, form: FormApi) => Promise<{}>,
    formMeta: FormMeta,
    data: Task,
    formsState: Signal<FormState[]>
}

const TaskHeaderForm = ({
    onSubmit,
    formMeta,
    data,
    formsState
}: TaskHeaderFormProps) => {
    const formRef = useRef<HTMLFormElement>(null);

    return (
        <Form
            onSubmit={onSubmit}
            formMeta={formMeta}
            initialValues={data}
            mutators={{
                ...arrayMutators,
                updateArrayField: (args, state, utils) => {
                    const [arrayName, rowIndex, fieldName, newValue] = args;
                    utils.changeValue(state, `${arrayName}[${rowIndex}].${fieldName}`, (_) => newValue)
                },
            }}
            subscription={{}}
            render={({
                 handleSubmit,
                 form: { mutators: { updateArrayField, push } }
             }) => (
                <form style={{width: '100%', flexGrow: 1}} ref={formRef} onSubmit={handleSubmit}>
                    <FormStateControl name={'TaskAgenda'} formRef={formRef} formsState={formsState} />
                    <TaskAgendaField
                        updateRowField={(index: number, name: string, value: any) => updateArrayField('agendaItems', index, name, value)}
                        addNewRow={(rowData: any) => push('agendaItems', rowData)}
                    />
                </form>
            )}
        />
    )
}

export default React.memo(TaskHeaderForm)