import React, {useEffect} from "react";
import AdvancedDataGrid from "../../../components/dataGrid/DataGrid";
import {Project, ProjectsListGridParams} from "../../../features/project/project.interface";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {GridColDef, ValueOptions} from "@mui/x-data-grid";
import {useAppDispatch, useTypedSelector} from "../../../app/store";
import {setComponentIsLoaded} from "../../../components/waitForLoad/waitForLoadSlice";
import {useLazyProjectsListQuery} from "../../../features/project/projectApi";
import {useDataGridUtils} from "../../../components/dataGrid/dataGridUtils";
import {ListRequest} from "../../../api/apiRequest.interface";
import {ListResponse} from "../../../api/apiResponse.interface";
import {selectProjectFormMeta} from "../../../features/project/projectMetaSlice";
import AppLoader from "../../../components/appLoader/AppLoader";

const ProjectsListGrid = ({
    openProjectDialog,
    gridApiRef
}: ProjectsListGridParams) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const datagridUtils = useDataGridUtils();
    const projectDetailsFormMeta = useTypedSelector((state) => selectProjectFormMeta(state, 'project_edit'))
    const [loadList] = useLazyProjectsListQuery()

    useEffect(() => {
        dispatch(setComponentIsLoaded({
            componentName: ProjectsListGrid.name,
            state: false
        }))
    }, [])

    if (!projectDetailsFormMeta) {
        return <AppLoader/>
    }

    const gridColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'projectList.table.name',
            flex: 2,
            type: "string",
            minWidth: 250,
        },
        {
            field: 'type',
            headerName: 'projectList.table.type',
            flex: 1,
            type: "string",
            sortable: false,
            filterable: false,
            minWidth: 150,
            valueFormatter: (formatterParams) => formatterParams.value ? t(formatterParams.value.label) : ''
        },
        {
            field: 'status',
            headerName: 'projectList.table.status',
            flex: 1,
            type: "singleSelect",
            minWidth: 150,
            valueFormatter: (formatterParams) => formatterParams.value ? t(formatterParams.value.label) : '',
            valueOptions: () => projectDetailsFormMeta.fields['status'].choices as ValueOptions[]
        },
        {
            field: 'updatedAt',
            headerName: 'projectList.table.update',
            flex: 1,
            type: "dateTime",
            minWidth: 200,
            valueFormatter: datagridUtils.formatDateTime,
        },
    ];

    const getGridRow = (project: Project, index: number) => {
        return {
            id: index,
            name: project.name,
            type: project.type,
            status: project.status,
            updatedAt: project.updatedAt ? new Date(project.updatedAt * 1000) : null
        }
    }

    return (
        <AdvancedDataGrid<Project>
            gridApiRef={gridApiRef}
            onComponentIsLoaded={() => {
                dispatch(setComponentIsLoaded({
                    componentName: ProjectsListGrid.name,
                    state: true
                }))
            }}
            gridColumns={gridColumns}
            getGridRow={getGridRow}
            loadList={async (listRequest: ListRequest): Promise<ListResponse> => {
                return await loadList(listRequest, true).unwrap()
            }}
            onRowClick={(rowData) => navigate(`/project/${rowData['hash'] as string}`)}
            defaultSort={[
                { field: 'updatedAt', sort: 'desc' }
            ]}
            noRowsOverlayProps={{
                onClickHandler: () => openProjectDialog(),
                noResultsText: t('projectList.noProjects'),
                buttonText: t('projectList.createFirst')
            }}
            enableFilters
        />
    );
}

export default ProjectsListGrid