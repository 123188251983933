import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import theme from "../../../app/theme";
import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import {Project} from "../../../features/project/project.interface";
import Box from "@mui/material/Box";

const TasksProgressBlock = ({project}: {project: Project}) => {

    if (!project.totalTasks) {
        return null;
    }

    const progress = project.totalCompletedTasks
        ? Math.round((project?.totalCompletedTasks / project.totalTasks) * 100)
        : 0

    return (
        <React.Fragment>
            <Grid item container flexDirection={"row"} alignItems={'center'} px={1}>
                <Grid item flexGrow={1}>
                    <Typography variant={'h6'} color={theme.palette.secondary.dark} sx={{
                        [theme.breakpoints.down("sm")]: {
                            fontSize: '0.9em',
                        },
                    }}>{(project?.totalTasks) - (project?.totalCompletedTasks || 0)} tasks to complete</Typography>
                </Grid>
                <Grid item>
                    <Typography color={theme.palette.secondary.main} sx={{
                        [theme.breakpoints.down("sm")]: {
                            fontSize: '0.9em',
                        },
                    }}>{project?.totalCompletedTasks}/{project?.totalTasks}</Typography>
                </Grid>
            </Grid>
            <Box width={'100%'}>
                <ProgressBar bgColor={
                    `repeating-linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.main} 10px, ${theme.palette.primary.light} 10px, ${theme.palette.primary.light} 20px)`
                } completed={progress}/>
            </Box>
        </React.Fragment>
    )
}

export default React.memo(TasksProgressBlock)