import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../app/store'
import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {ValidationError} from "../service/validator/validation.error";
import {logout} from "../features/auth/authSlice";
import {ApiError} from "./api.interface";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_DOMAIN}/api/v1/`,
    timeout: 5000,
    credentials: process.env.REACT_APP_DEBUG ? "include" : undefined,
    prepareHeaders: (headers, { getState }) => {

        headers.set('Accept', 'application/json')
        headers.set('Content-Type', 'application/json')

        if (process.env.REACT_APP_DEBUG) {
            headers.set('Cookie', 'XDEBUG_SESSION=PHPSTORM')
        }

        const token = (getState() as RootState).auth.token
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    },
})

const handleErrorResponse = (response: { status: number | string, data: {errors: { [key: string]: any }} }, api: MiddlewareAPI): ApiError|ValidationError => {

    const errors = response.status === 'FETCH_ERROR'
        ? {messages: ['error.serverNotAvailable']}
        : response.data.errors

    if (errors?.fields) {
        return new ValidationError(errors?.fields)
    }

    if (response.status === 401) {
        api.dispatch(logout())
    }

    const messages = errors?.messages
        ? errors?.messages
        : ['error.unknown']

    return {messages: messages}
}

export const rtkQueryErrorProcessor: Middleware = (api: MiddlewareAPI) => (next) => (action) => {

    if (isRejectedWithValue(action)) {
        action.payload = action.payload.code === 'ECONNABORTED'
            ? {messages: [action.payload.message]}
            : handleErrorResponse(action.payload, api)
    }

    return next(action)
}

export const api = createApi({
    baseQuery: baseQuery,
    tagTypes: ['Metadata'],
    endpoints: () => ({}),
})