import {api} from "../../api/api";
import {TaskAttachment} from "./task.interface";
import {axiousApi} from "../../api/axiosConfigs";
import {RootState} from "../../app/store";
import {BaseQueryApi, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {deleteProgress, setProgress} from "../../components/progressBar/progressBarSlice";
import {addTempAttachment} from "./taskSlice";

const transformAttachmentsResponse = (response: {
    status: number
    data: TaskAttachment[]
}) => response.data

type CreateTaskAttachmentParams = {
    projectHash: string,
    taskId: number,
    tempName: string,
    progressCategory: string,
    fileData: FormData
}

type DownloadTaskAttachmentParams = {
    projectHash: string,
    taskId: number,
    attachmentId: number,
    updateProgress: (progress: number) => void
}

export const taskAttachmentsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createTaskAttachment: builder.mutation<TaskAttachment[], CreateTaskAttachmentParams>({
            // @ts-ignore
            queryFn: async (arg: CreateTaskAttachmentParams, api: BaseQueryApi) => {
                try {
                    const file = arg.fileData.get('file') as File
                    api.dispatch(addTempAttachment({
                        projectHash: arg.projectHash,
                        taskId: arg.taskId,
                        attachment: {
                            name: file.name,
                            extension: '',
                            number: 0,
                            tempName: arg.tempName
                        }
                    }))
                    const token = (api.getState() as RootState).auth.token
                    const response = await axiousApi.request({
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Authorization": "Bearer " + token
                        },
                        url: `project/${arg.projectHash}/task/${arg.taskId}/attachment/create`,
                        method: 'POST',
                        data: arg.fileData,
                        // 1 hour
                        timeout: 3600000,
                        onUploadProgress: event => {
                            const progress = Math.round((event.loaded / (event.total || 1)) * 100)
                            api.dispatch(setProgress({category: arg.progressCategory, id: arg.tempName, progress}));
                        }
                    })

                    return response.data as {
                        status: number
                        data: TaskAttachment[]
                    }

                } catch (error) {
                    return {error} as FetchBaseQueryError
                } finally {
                    api.dispatch(deleteProgress({category: arg.progressCategory, id: arg.tempName}));
                }
            },
            transformResponse: transformAttachmentsResponse
        }),

        downloadAttachment: builder.mutation<Blob, DownloadTaskAttachmentParams>({
            // @ts-ignore
            queryFn: async (arg: DownloadTaskAttachmentParams, api: BaseQueryApi) => {
                try {
                    const token = (api.getState() as RootState).auth.token
                    return await axiousApi.request({
                        headers: {
                            "Authorization": "Bearer " + token
                        },
                        url: `project/${arg.projectHash}/task/${arg.taskId}/attachment/${arg.attachmentId}/get`,
                        method: 'GET',
                        // 1 hour
                        timeout: 3600000,
                        responseType: "blob",
                        onDownloadProgress: progressEvent => {
                            if (progressEvent.total) {
                                arg.updateProgress(Math.floor((progressEvent.loaded / progressEvent.total) * 100))
                            }
                        }
                    })

                } catch (error) {
                    return {error} as FetchBaseQueryError
                }
            },
        }),

        editTaskAttachment: builder.mutation<TaskAttachment[], {
            projectHash: string,
            taskId: number,
            attachmentId: number,
            data: { [key: string]: any }
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/${queryData.taskId}/attachment/${queryData.attachmentId}/edit`,
                method: 'PUT',
                body: queryData.data,
            }),
            transformResponse: transformAttachmentsResponse
        }),
        deleteTaskAttachment: builder.mutation<TaskAttachment[], {
            projectHash: string,
            taskId: number,
            attachmentId: number,
        }>({
            query: (queryData) => ({
                url: `project/${queryData.projectHash}/task/${queryData.taskId}/attachment/${queryData.attachmentId}/delete`,
                method: 'DELETE',
            }),
            transformResponse: transformAttachmentsResponse
        }),
    }),
})

export const {
    useCreateTaskAttachmentMutation,
    useEditTaskAttachmentMutation,
    useDeleteTaskAttachmentMutation,
    useDownloadAttachmentMutation
} = taskAttachmentsApi

export const {
    endpoints: { createTaskAttachment, deleteTaskAttachment, editTaskAttachment, downloadAttachment },
} = taskAttachmentsApi