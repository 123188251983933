import React from "react";
import {GridFilterInputValueProps} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {DateRangePicker} from "../fields/dateRangePicker/DateRangePicker";
import {DateRangePickerProps} from "../fields/dateRangePicker/DateRangePicker.types";
import {useTranslation} from "react-i18next";
import dayjs, {Dayjs} from "dayjs";
import {DateRange} from "../fields/dateRangePicker/range";
dayjs.extend(require('dayjs/plugin/localizedFormat'))

const DateTimeRangeFilter = (
    props: GridFilterInputValueProps & {
        headerFilterMenu: React.ReactNode;
        clearButton: React.ReactNode;
    },
) => {
    const { item, applyValue, clearButton } = props;
    const {t} = useTranslation();
    const datetimeRef: React.Ref<any> = React.useRef(null);

    const handleFilterChange: DateRangePickerProps<any>["onChange"] = (newValue: DateRange<Dayjs>) => {
        applyValue({ ...item, value: {
            left_datetime: newValue?.[0]?.unix() || null,
            right_datetime: newValue?.[1]?.unix() || null
        }});
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: "center"}}>
            <DateRangePicker
                value={[
                    item.value?.['left_datetime'] ? dayjs(String(item.value['left_datetime']), 'X') : null,
                    item.value?.['right_datetime'] ? dayjs(String(item.value['right_datetime']), 'X') : null
                ]}
                ref={datetimeRef}
                onChange={handleFilterChange}
                sx={{mr: '5px', width: '250px'}}
                localeText={{ start: t("gridFilters.dateRange.from") || '', end: t("gridFilters.dateRange.to") || '' }}
            />
            {clearButton}
        </Box>
    );
}

export default React.memo(DateTimeRangeFilter)