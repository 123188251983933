import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import {Signal} from "@preact/signals-react";
import {FormState} from "./form.interface";
import {LoadingButtonProps} from "@mui/lab/LoadingButton/LoadingButton";

type FormSubmitButtonProps = {
    title: string,
    formsState: Signal<FormState[]>
} & LoadingButtonProps

const FormSubmitButton = (props: FormSubmitButtonProps) => {
    const { title, formsState, ...buttonRest } = props;
    const valid = !!formsState.value.find((value) => value.valid)
    const changed = !!formsState.value.find((value) => value.changed)
    const submitting = !!formsState.value.find((value) => value.submitting)

    return (
        <LoadingButton
            variant={"contained"}
            color={"primary"}
            size={"large"}
            {...buttonRest}
            disabled={!valid || !changed}
            loading={submitting}
            onClick={() => {
                formsState.value.map((value) => {
                    if (value.changed) {
                        value.formRef.current?.requestSubmit()
                    }
                })
            }}
        >
            {title}
        </LoadingButton>
    )
}

export default React.memo(FormSubmitButton)