import theme from "../../app/theme";

const styles = (theme: any) => {
    return {
        width: '100%',
        marginTop: '25rem',

        '& .app-loader': {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .bouncing-loader': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& .bouncing-loader > div': {
            width: '3rem',
            height: '3rem',
            margin: '6rem 0.4rem',
            background: theme.palette.primary.main,
            borderRadius: '50%',
            animation: 'bouncing-loader 0.6s infinite alternate'
        },
        '& .bouncing-loader > div:nth-of-type(2)': {
            animationDelay: '0.2s'
        },
        '& .bouncing-loader > div:nth-of-type(3)': {
            animationDelay: '0.4s'
        },
        '@keyframes bouncing-loader': {
            'to': {
                opacity: '0.1',
                transform: 'translate3d(0, -1rem, 0)'
            }
        }
    };
}

export const appLoaderStyles = styles(theme);