import React from "react";
import {styled} from "@mui/material/styles";
import {appLoaderStyles} from "./appLoaderStyles";

const StyledAppLoader = styled('div')(appLoaderStyles);

const AppLoader = () => {
    return (
        <StyledAppLoader>
            <section className='app-loader'>
                <div className='bouncing-loader'>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </section>
        </StyledAppLoader>
    )
}

export default AppLoader