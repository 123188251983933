import React from "react";
import MuiPhoneNumber, {MuiPhoneNumberProps} from "material-ui-phone-number";
import {Field, FieldRenderProps} from 'react-final-form';
import {showErrorOnChange} from 'mui-rff';
import {PhoneNumberProps} from "./phoneNumber.interface";

function PhoneNumber(props: PhoneNumberProps) {
    const { name, fieldProps, ...rest } = props;

    return (
        <Field
            name={name}
            render={(fieldRenderProps) => <PhoneNumberWrapper {...fieldRenderProps} {...rest} />}
            {...fieldProps}
        />
    );
}

type PhoneNumberWrapperProps = FieldRenderProps<MuiPhoneNumberProps>;

function PhoneNumberWrapper(props: PhoneNumberWrapperProps) {
    const {
        input: { name, onChange, value, ...restInput },
        meta,
        showError = showErrorOnChange,
        ...rest
    } = props;
    const { error, submitError } = meta;
    const isError = showError({ meta });
    const { helperText, ...lessRest } = rest;

    const formatValue = (value: any) => {
        const replaceReg = RegExp('[\)\(\\s]', 'g');

        return typeof value === 'string'
            ? value.replace(replaceReg, '')
            : value;
    }

    return (
        <MuiPhoneNumber
            onChange={(value) => onChange({
                target: {value: formatValue(value)}} as React.ChangeEvent<any>
            )}
            value={(value as any) === '' ? null : value}
            helperText={isError ? error || submitError : helperText}
            inputProps={{
                onBlur: (event) => {
                    restInput.onBlur(event);
                },
                onFocus: (event) => {
                    restInput.onFocus(event);
                },
            }}
            error={isError}
            name={name}
            {...lessRest}
        />
    );
}

export default PhoneNumber;