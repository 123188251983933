import ListBlock from "../../../components/listBlock/ListBlock";
import React, {ChangeEvent, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {setComponentIsLoaded} from "../../../components/waitForLoad/waitForLoadSlice";
import {useAppDispatch, useTypedSelector} from "../../../app/store";
import {TaskAttachment, TaskAttachmentsListBlockParams} from "../../../features/task/task.interface";
import {getTaskAttachments} from "../../../features/task/taskSlice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import TaskAttachmentDialog from "../../../features/task/TaskAttachmentDialog";
import {isCategoryInProgress} from "../../../components/progressBar/progressBarSlice";
import {DefaultExtensionType, defaultStyles, FileIcon} from "react-file-icon";
import ProgressBar from "../../../components/progressBar/ProgressBar";
import {displayErrors} from "../../../service/errorService";
import {useCreateTaskAttachmentMutation, useDownloadAttachmentMutation} from "../../../features/task/taskAttachmentsApi";
import {DownloadButton} from "../../../components/downloadButton/DownloadButton";

const TaskAttachmentsListBlock = ({
    projectHash,
    taskId
}: TaskAttachmentsListBlockParams) => {
    const [attachmentDialogState, setAttachmentDialogState] = useState<{open: boolean, attachment?: TaskAttachment}>({open: false})
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const progressCategory = `${projectHash}-${taskId}-taskAttachments`
    const taskAttachments = useTypedSelector((state) => getTaskAttachments(state, projectHash, taskId))
    const uploadInProgress = useTypedSelector((state) => isCategoryInProgress(state, progressCategory))
    const [createTaskAttachment] = useCreateTaskAttachmentMutation()
    const [downloadTaskAttachment] = useDownloadAttachmentMutation()

    useEffect(() => {
        dispatch(setComponentIsLoaded({
            componentName: TaskAttachmentsListBlock.name,
            state: taskAttachments !== undefined
        }))

    }, [taskAttachments]);

    if (taskAttachments === undefined) {
        return null
    }

    function getListItemLine(item: TaskAttachment): React.ReactElement {
        const fullFileName = item.name + (item.extension ? `.${item.extension}` : '');
        const fileIconType = item.extension in defaultStyles ? defaultStyles[item.extension as DefaultExtensionType] : defaultStyles.doc
        const apiDefinition = async (updateProgress: (progress: number) => void) => await downloadTaskAttachment({
            projectHash,
            taskId,
            attachmentId: item.number,
            updateProgress
        }).unwrap()

        return (
            <React.Fragment>
                <Grid container alignItems={"center"} spacing={1}>
                    {!!item.number && <Grid item textAlign={"start"}>
                        <DownloadButton apiDefinition={apiDefinition} fileName={fullFileName}/>
                    </Grid>}
                    <Grid item justifyContent={"flex-start"} xs={1}>
                        <FileIcon extension={item.extension} {...fileIconType} />
                    </Grid>
                    <Grid item justifyContent={"flex-start"} xs={9}>
                        <Typography variant={"caption"} sx={{wordBreak: "break-word", fontWeight: "bold"}}>{fullFileName}</Typography>
                    </Grid>
                </Grid>
                {item.tempName && <ProgressBar category={progressCategory} id={item.tempName} />}
            </React.Fragment>
        )
    }

    const uploadFile = async (file: File) => {
        const fileData = new FormData()
        fileData.append('file', file)

        try {
            await createTaskAttachment({projectHash, taskId, tempName: file.name + (Math.random() * 1000), fileData, progressCategory})
        } catch (e) {
            displayErrors(e);
        }
    }

    const handleFilesUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files) {
            return null;
        }

        [...event.target.files].map(async (file) => {
            await uploadFile(file)
        });

        event.target.value = ''
    };

    return (
        <React.Fragment>
            {attachmentDialogState?.attachment && <TaskAttachmentDialog
                open={attachmentDialogState.open}
                setOpen={(state) => setAttachmentDialogState(old => ({...old, open: state}))}
                taskId={taskId}
                attachment={attachmentDialogState.attachment}
                projectHash={projectHash}
            />}
            <ListBlock
                getItemLine={getListItemLine}
                items={taskAttachments}
                editButtonOnClick={uploadInProgress
                    ? undefined
                    : (itemIndex) => setAttachmentDialogState({
                        open: true,
                        attachment: taskAttachments[itemIndex],
                    })
                }
            />
            <Box sx={{ display: 'flex' }} justifyContent={"center"} alignContent={"center"}>
                <Button component="label" variant="contained" color={"primary"} startIcon={<InsertDriveFile/>}>
                    <input hidden multiple onChange={handleFilesUpload} type="file" />
                    {t('taskAttachments.addNew')}
                </Button>
            </Box>
        </React.Fragment>
    )
}

export default React.memo(TaskAttachmentsListBlock)