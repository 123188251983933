import {Box, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AccountCircle} from "@mui/icons-material";
import theme from "../../app/theme";
import {getCurrentUser, logout} from "../../features/auth/authSlice";
import * as React from "react";
import {useAppDispatch} from "../../app/store";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

type UserMenuProps = {
    showUserName: boolean
}

const UserMenu = ({
    showUserName = true
}: UserMenuProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const user = useSelector(getCurrentUser)

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <React.Fragment>
            <Tooltip title={t('header.account')}>
                <Box textAlign={'center'}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <AccountCircle sx={{fontSize: '4.5rem', color: theme.palette.secondary.dark}} />
                    </IconButton>
                    {showUserName && <Typography variant={"body2"} mt={1}>{user?.username}</Typography>}
                </Box>
            </Tooltip>
            <Menu
                sx={{ mt: '35px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem key={0} onClick={() => dispatch(logout())}>
                    <Typography textAlign="center"><Trans i18nKey={'header.logout'} /></Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default React.memo(UserMenu)