import React, {useState} from "react";
import CreateProjectDialog from "./CreateProjectDialog";
import {useTranslation} from "react-i18next";
import {
    GridAddIcon,
} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {AddNewProjectButtonParams} from "./project.interface";

const AddNewProjectButton = ({
    buttonRef
}: AddNewProjectButtonParams) => {
    const [createDialogState, setCreateDialogState] = useState<boolean>(false)
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <CreateProjectDialog open={createDialogState} setOpen={setCreateDialogState} />
            <Button sx={{whiteSpace: "nowrap"}} ref={buttonRef} variant="contained" onClick={() => setCreateDialogState(true)} startIcon={<GridAddIcon />}>
               {t('projectList.addNew')}
            </Button>
        </React.Fragment>
    )
}

export default React.memo(AddNewProjectButton)