export interface ValidationMessage {
    fieldName: string,
    messages: {
        message: string,
        params?: {}
    }[],
}

export class ValidationError {

    protected readonly validationMessages: ValidationMessage[] = []

    constructor(validationMessages: ValidationMessage[]) {
        this.validationMessages = validationMessages
    }

    public getValidationMessages = () => {
        return this.validationMessages
    }
}