import * as React from "react"
import { SVGProps } from "react"
interface SVGRProps {
    title?: string;
    titleId?: string;
}
const WorkerIcon = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width="1em"
        height="1em"
        fill={props.fill}
        viewBox="0 0 512 512"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path d="M478.162 275.416c-5.687-88.983-65.319-165.555-150.231-192.725-3.134-24.627-24.205-43.735-49.671-43.735h-44.522c-25.465 0-46.536 19.108-49.67 43.735-84.91 27.17-144.543 103.742-150.23 192.725C14.175 282.181 0 300.851 0 322.783c0 23.279 15.966 42.899 37.521 48.489l14.897 44.691a83.37 83.37 0 0 0 79.195 57.081h248.775a83.371 83.371 0 0 0 79.194-57.08l14.898-44.692c21.555-5.59 37.52-25.209 37.52-48.489 0-21.932-14.175-40.602-33.838-47.367zM328.348 118.245c64.651 25.148 109.82 84.98 116.108 154.45H328.348v-154.45zM217.043 89.044c0-9.206 7.49-16.696 16.696-16.696h44.522c9.206 0 16.696 7.49 16.696 16.696v183.652h-77.913V89.044zm-33.391 29.201v154.45H67.544c6.289-69.47 51.457-129.302 116.108-154.45zm244.252 287.159a50.023 50.023 0 0 1-47.516 34.248H131.613a50.024 50.024 0 0 1-47.517-34.248L73.252 372.87H438.75l-10.846 32.534zm34.009-65.926H50.087c-9.206 0-16.696-7.49-16.696-16.696 0-9.206 7.49-16.696 16.696-16.696h411.826c9.206 0 16.696 7.49 16.696 16.696 0 9.207-7.49 16.696-16.696 16.696z" />
    </svg>
)
export default WorkerIcon