import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import {enqueueSnackbar} from "notistack";
import {useTypedSelector} from "../../app/store";
import AppLoader from "../../components/appLoader/AppLoader";
import {selectTaskFormMeta} from "../../features/task/taskMetaSlice";
import {useGetTaskQuery, useTaskMetadataQuery, useUpdateTaskMutation} from "../../features/task/taskApi";
import {AnyObject} from "react-final-form";
import {getOnlyDirtyValues, processSubmitErrors} from "../../components/form/formUtils";
import {getTask} from "../../features/task/taskSlice";
import {FormApi} from "final-form";
import {signal} from "@preact/signals-react";
import {FormState} from "../../components/form/form.interface";
import {Card, Container, Link} from "@mui/material";
import TaskHeaderForm from "./parts/TaskHeaderForm";
import LeftCard from "./parts/LeftCard";
import RightCard from "./parts/RightCard";

const TaskPage = () => {
    const { id, projectHash } = useParams();
    const {t} = useTranslation();
    const formsState = signal<FormState[]>([])
    const navigate = useNavigate();

    if (projectHash === undefined || id === undefined) {
        navigate('/');
        enqueueSnackbar(t('task.notFound'), {variant: "error"})
        return null;
    }

    const taskDetailsFormMeta = useTypedSelector((state) => selectTaskFormMeta(state, 'task_edit'))
    const {isLoading: isLoadingTaskMeta , isError: isErrorTaskMeta} = useTaskMetadataQuery({projectHash: projectHash})
    const task = useTypedSelector((state) => getTask(state, projectHash, parseInt(id)))
    const {isLoading: isLoadingTask, isError: isErrorTask} = useGetTaskQuery({taskId: parseInt(id), projectHash: projectHash})
    const [updateTask] = useUpdateTaskMutation()

    if (isLoadingTaskMeta || isLoadingTask || !task || !taskDetailsFormMeta) {
        return <AppLoader/>
    }

    if (isErrorTask || isErrorTaskMeta) {
        throw new Error('Can\'t load the project')
    }

    const onSubmitTaskDetails = async (_: AnyObject, form: FormApi) => {
        let errors = {}
        let values = getOnlyDirtyValues(form)

        try {
            await updateTask({projectHash: projectHash, taskId: parseInt(id), data: values}).unwrap()
            enqueueSnackbar(t('task.successfullySaved'), {variant: "success"})
        } catch (e) {
            errors = processSubmitErrors(e, taskDetailsFormMeta)
        }

        return errors
    }

    return (
        <Container maxWidth={"lg"} sx={{pr: {xs: 0}, pb: 2}}>
            <Grid container direction={'column'} spacing={2} sx={{flexGrow: 1, width: '100%'}}>
                <Grid item>
                    <Card sx={{pr: {xs: 3, md: 5}, pl: {xs: 7, md: 5}, py: 2}}>
                        <Grid item container rowSpacing={1}>
                            <Grid item xs={12}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => navigate(`/project/${task?.project.hash}`)}
                                >{`< ${task?.project.name}`}</Link>
                            </Grid>
                            <Grid item xs={12}>
                                <TaskHeaderForm
                                    onSubmit={onSubmitTaskDetails}
                                    formMeta={taskDetailsFormMeta}
                                    formsState={formsState}
                                    data={task}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item sx={{flex: 1, flexGrow: 1, width: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={7}>
                            <LeftCard
                                onSubmit={onSubmitTaskDetails}
                                formMeta={taskDetailsFormMeta}
                                formsState={formsState}
                                task={task}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <RightCard
                                projectHash={projectHash}
                                taskId={parseInt(id)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default TaskPage;
