import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {User} from "../user/user.interface";
import {authApi} from "./authApi";

const initialState = {
    user: null,
    token: null,
    isAuthenticated: false,
} as { user: null | User; token: string | null; isAuthenticated: boolean }

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                state.user = { username: 'App User' }
                state.token = action.payload.token
                state.isAuthenticated = true
            })
    },
})

export const { logout } = slice.actions
export default slice.reducer

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated
export const getCurrentUser = (state: RootState) => state.auth.user